import React, { useState, useCallback } from 'react';
// import { MediaContainer, MediaOverlay } from '@react-md/media';
// import { CircularProgress } from '@react-md/progress';
// import { TabPanel } from '@react-md/tabs';
// import { Text } from '@react-md/typography';
// import { GridList, GridListCell } from '@react-md/utils';
// import { List, ListItem } from '@react-md/list';
// import Container from './Container';
// import InfiniteScroll from 'react-infinite-scroller';
// import { useTimeout, useToggle } from '@react-md/utils';
// import { CachedSVGIcon, CallReceivedFontIcon, SearchSVGIcon } from '@react-md/material-icons';
// import { TextIconSpacing } from '@react-md/icon';
import { Button } from '@react-md/button';
// import { Chip } from '@react-md/chip';
// import { useHistory } from "react-router-dom";
// import {
//   Form,
//   TextField,
// } from '@react-md/form';
// import { FontIcon } from '@react-md/icon';
import { AppBar, AppBarNav, AppBarTitle } from '@react-md/app-bar';
import {
  ArrowBackSVGIcon,
  ArrowUpwardSVGIcon
} from '@react-md/material-icons';

import { withRouter } from "react-router-dom";

import { useAppSize } from '@react-md/utils';
import { MediaContainer } from '@react-md/media';
import logo from './logo.png';
import { Text, TextContainer } from "@react-md/typography";

const About = (props) => {

  const context = useAppSize();

  const [visible, setVisible] = useState(false);

  const toggleVisible = () => { 
    const scrolled = document.documentElement.scrollTop; 
    if (scrolled > 0){ 
      setVisible(true) 
    }  
    else if (scrolled <= 0){ 
      setVisible(false) 
    } 
  };

  const scrollToTop = () =>{ 
    window.scrollTo({ 
      top: 0, 
      behavior: 'auto'
      /* you can also use 'auto' behaviour 
         in place of 'smooth' */
    }); 
  }; 

  window.addEventListener('scroll', toggleVisible);

  const getLayoutWidth = (lytdata) => {
    if(lytdata.isPhone){
      return '100%';
    }
    if(lytdata.isTablet){
      return '50%';
    }
    if(lytdata.isDesktop){
      return '40%';
    }
    if(lytdata.isLargeDesktop){
      return '30%';
    }
  }

  return (
    <div>
      <AppBar theme="clear" height="none">
        <AppBarNav id="TagBack" aria-label="Back" onClick={props.history.goBack}>
          <ArrowBackSVGIcon />
        </AppBarNav>
        <AppBarTitle
          style={{display: 'flex', alignItems: 'center',}}
        >
          About
        </AppBarTitle>
      </AppBar>
      <div style={{display: 'flex', flexDirection: 'column', padding: 5, paddingBottom: 100,}}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
            {/* <MediaContainer><img src={logo} alt="logo" /></MediaContainer> */}
            <img src={logo} alt="logo" style={{maxHeight: 50, maxWidth: 50, resizeMode: 'contain',}} />
            <span style={{fontSize: 25, fontWeight: 'bold'}}>Numero Kno</span>
        </div>
        <span style={{fontSize: 18, fontWeight: 'bold', marginTop: 10, marginBottom: 5,}}>Personalized Presentation & Analysis of Data</span>
        <span style={{fontSize: 16,}}>
            Each day we read several pages / reports and articles online. The amount of data we skim through keeps adding up over time. While we wish we remember all useful material we read, we cannot. If we were able to reproduce the data on demand and better still analyze and pull relevant data points for later reference that would be a greatly valuable outcome of our online reading.
        </span>
        <span style={{fontSize: 16,}}>
            Numero Kno takes notes of data points from the information you read, and presents it in an visual manner for easy reference. It understands what your interests are and priorities those relevant subjects in your feed. 
            You read an interesting article on the year-on-year increase in retail growth, which had some very useful data points. You can easily take note in Numero Kno, keep a link of the article and even better share the information with the community.
            You can add several subjects of your interest and can customize what you would like to see.
        </span>
        <span style={{fontSize: 16,}}>
            You share a lot of smart information with your friends, colleagues and community. With Numero Kno, you can always easily retrieve the properly indexed links or just look at the relevant data points and charts.
            We cannot cram all the information we read in our brains, we don’t have to.
        </span>
        <span style={{fontSize: 18, fontWeight: 'bold', marginTop: 10, marginBottom: 5,}}>Community</span>
        <span style={{fontSize: 16,}}>
            Your interests are shared by the community, there are people who have 
            similar interests as you do and all of them are reading and processing 
            publicly available information, some of which you may not have seen. 
            The Numero Kno community enriches the usage of the platform as it makes 
            notes and references to relevant and high quality data points. These 
            references lead to better analysis for others.
        </span>
        <span style={{fontSize: 20, fontWeight: 'bold', marginTop: 10, marginBottom: 5,}}>Features</span>
        <span style={{fontSize: 18, fontWeight: 'bold', marginTop: 10, marginBottom: 5,}}>Data Notes</span>
        <span style={{fontSize: 16,}}>
            While reading an article on your mobile device or the computer, you make 
            notes and share those on Numero Kno. 
        </span>
        <span style={{fontSize: 18, fontWeight: 'bold', marginTop: 10, marginBottom: 5,}}>Archive Index</span>
        <span style={{fontSize: 16,}}>
            Numero Kno creates an easy-reference index of all data shared pages / 
            articles. 
        </span>
        <span style={{fontSize: 16,}}>
            Just click on the keyworks to see the relevant info-graphics. Some of the 
            easy reference keywords are Economy, Commodities, Coal, Copper, Retail 
            and many more. 
        </span>
        <span style={{fontSize: 18, fontWeight: 'bold', marginTop: 10, marginBottom: 5,}}>Charts</span>
        <span style={{fontSize: 16,}}>
            Data is presented in easy to read charts where a relevant data point / 
            stream is available. 
        </span>
        <span style={{fontSize: 18, fontWeight: 'bold', marginTop: 10, marginBottom: 5,}}>Smart Feed</span>
        <span style={{fontSize: 16,}}>
            Numero Kno uses advanced features to continuously review the web for 
            articles and reports of your interest, it will also highlight relevant data points 
        </span>
        <span style={{fontSize: 16,}}>
            automatically. You can make a note of the relevant information, which then 
            appears as charts. 
        </span>

        { visible ? <Button floating="bottom-left" theme="clear" onClick={scrollToTop}><ArrowUpwardSVGIcon /></Button> : null }
      </div>
    </div>
  );
};

export default withRouter(About);