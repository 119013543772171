import React, { useState, useCallback } from 'react';
// import { MediaContainer, MediaOverlay } from '@react-md/media';
import { CircularProgress } from '@react-md/progress';
// import { TabPanel } from '@react-md/tabs';
// import { Text } from '@react-md/typography';
// import { GridList, GridListCell } from '@react-md/utils';
import { List, ListItem } from '@react-md/list';
import CustomRow from './GraphDesign';
import CustomRowBlog from './TrendingDesign';
import CustomRowText from './TbDesign';
import Container from './Container';
// import InfiniteScroll from 'react-infinite-scroller';
// import { useTimeout, useToggle } from '@react-md/utils';
import { CachedSVGIcon, ArrowBackSVGIcon, CloseSVGIcon, ArrowUpwardSVGIcon } from '@react-md/material-icons';
// import { TextIconSpacing } from '@react-md/icon';
import { Button } from '@react-md/button';
import { Chip } from '@react-md/chip';
// import { useHistory } from "react-router-dom";
// import {
//   Form,
//   TextField,
// } from '@react-md/form';
import { FontIcon } from '@react-md/icon';
import { AppBar, AppBarNav, AppBarTitle } from '@react-md/app-bar';
import { useSelector, useDispatch } from "react-redux";
import { setOverlay } from './reducer';
import { Overlay } from "@react-md/overlay";


import { withRouter } from "react-router-dom";

import { useAppSize } from '@react-md/utils';
import logo from './logo.png';
// import { Controller, useForm } from 'react-hook-form';

// import { randomInt } from './random';

// var startVal = 0;
// var endVal = 10;

const SearchGraph = (props) => {

  const context = useAppSize();

  const getLayoutWidth = (lytdata) => {
    if(lytdata.isPhone){
      return '100%';
    }
    if(lytdata.isTablet){
      return '50%';
    }
    if(lytdata.isDesktop){
      return '40%';
    }
    if(lytdata.isLargeDesktop){
      return '30%';
    }
  }

  // const {
  //   control,
  //   reset,
  //   handleSubmit,
  //   errors: { serachData },
  // } = useForm({ mode: 'onChange' });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  // const [loadingtopic, setLoadingtopic] = useState(true);
  const [data, setData] = useState([]);
  // const [datatopic, setDatatopic] = useState([]);
  const [loadmore, setLoadmore] = useState(false);

  const [loadingmore, setLoadingmore] = useState(false);

  const [start_val, incStart] = useState(0);
  const [end_val, incEnd] = useState(10);
  const [textData, setTextdata] = useState('');
  const [errorText, setErrortext] = useState('');
  const [offsetval, setOffset] = useState(0);
  const blogOverlayDisp = useSelector(state => state.isBlogOverlay);
  const textOverlayDisp = useSelector(state => state.isTextOverlay);
  const blogOverlayData = useSelector(state => state.blogOverlay);
  const textOverlayData = useSelector(state => state.textOverlay);

  const [visible, setVisible] = useState(false);

  const toggleVisible = () => { 
    const scrolled = document.documentElement.scrollTop; 
    if (scrolled > 0){ 
      setVisible(true) 
    }  
    else if (scrolled <= 0){ 
      setVisible(false) 
    } 
  };

  const scrollToTop = () =>{ 
    window.scrollTo({ 
      top: 0, 
      behavior: 'auto'
      /* you can also use 'auto' behaviour 
         in place of 'smooth' */
    }); 
  }; 

  window.addEventListener('scroll', toggleVisible);

//   let history = useHistory();

  // const searchTextTB = useCallback(
  //   () => {
  //     alert("fff", textData);
  //   }, [textData],
  // )

  const handleSubmit = () => {
    // alert(`Submitting Name ${textData}`);
    if(textData.length === 0){
      setErrortext('Enter search text');
    }else if(textData.length <= 1){
      setErrortext('Search text should contain minimum 2 letters');
    }else{
      setErrortext('');
      loadFunc(textData);
    }
  }

  const displayOverlay = useCallback(
    (actn, stateName) => {
      //   console.log(JSON.stringify(topicList))
      dispatch(setOverlay({
        stateName: stateName,
        action: actn,
      }));
    },[dispatch],
  )

  const loadFunc = useCallback(
    (searchText) => {
        // setLoadingmore(true);
        // setErrortext(searchText);
        setLoading(true);
        setData([]);
        setLoadmore(false);
        setSearching(false);
        fetch('https://api.numerokno.com/api/graph_search/', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            "app-key": "E9FE46D9-FC53-480F-9DC6-D26A7DE233A0"
          },
          body: JSON.stringify({
            userId: 11,
            keyword: searchText,
            start_value: 0,
            end_value: 10,
            offset: 0,
          })
        }).then((response) => response.json())
          .then((json) => {
            // console.log(JSON.stringify(json));
            if(json.titbits.length === 0){
              setErrortext('No TitBit found');
            }else{
              setData(json.titbits);
              setLoading(false);
              setSearching(true);
              setLoadmore(true);
              setOffset(json.offset);
              incStart(10);
              incEnd(20);
            }
            
            // console.log(JSON.stringify(this.state.tbData));
          })
          .catch((error) => setErrortext('Oops! error has occurred'))
          .finally(() => {
            setLoading(false);
          });
        // })();
    },
    [],
  );

  const loadMoreFunc = useCallback(
    () => {
        setLoadingmore(true);
        fetch('https://api.numerokno.com/api/graph_search/', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            "app-key": "E9FE46D9-FC53-480F-9DC6-D26A7DE233A0"
          },
          body: JSON.stringify({
            userId: 11,
            keyword: textData,
            start_value: start_val,
            end_value: end_val,
            offset: offsetval,
          })
        }).then((response) => response.json())
          .then((json) => {
            // console.log(JSON.stringify(json));
            if(json.titbits.length === 0){
            //   setErrortext('No TitBit found');
                setLoadmore(false);
            }else{
              setData(prevState => ([...prevState, ...json.titbits]));
            //   setLoading(false);
            //   setSearching(true);
              setOffset(json.offset);
              setLoadingmore(false);
              incStart(end_val);
              incEnd(end_val + 10);
            }
            
            // console.log(JSON.stringify(this.state.tbData));
          })
          .catch((error) => setErrortext('Oops! error has occurred'))
          .finally(() => {
            setLoadingmore(false);
          });
        // })();
    },
    [textData, start_val, end_val, offsetval],
  );

//   useEffect(() => {
//     let cancelled = false;
//     setLoading(true);
    
//     (async function load() {
//       const response = await fetch('https://titbitss.pythonanywhere.com/api/all_titbits/', {
//         method: 'POST',
//         headers: {
//           Accept: 'application/json',
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({
//           user_id: 11,
//           start_value: 0,
//           end_value: 10
//         })
//       });
//       const result = await response.json();
//       const data = result;

//       if (!cancelled) {
//         setData(data);
//         setLoading(false);
//         setLoadmore(true);
//         incStart(10);
//         incEnd(20);
//       }
//     })();

//     return () => {
//       cancelled = true;
//     };
//   }, []);

//   useEffect(() => {
//     let cancelled = false;
    
//     (async function load() {
//       const response = await fetch('https://titbitss.pythonanywhere.com/api/topic_manage/', {
//         method: 'POST',
//         headers: {
//           Accept: 'application/json',
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({
//           func: 4
//         })
//       });
//       const result = await response.json();
//       const data = result;

//       if (!cancelled) {
//         setDatatopic(data);
//         setLoadingtopic(false);
//       }
//     })();

//     return () => {
//       cancelled = true;
//     };
//   }, []);



  return (
    <div>
      <AppBar theme="clear" height="none">
        <AppBarNav id="TagBack" aria-label="Back" onClick={props.history.goBack}>
          <ArrowBackSVGIcon />
        </AppBarNav>
        <AppBarTitle
          style={{display: 'flex', alignItems: 'center',}}
        >
          Search
        </AppBarTitle>
      </AppBar>
      <div>
        
        <div style={{width: '100%',display: 'flex', flexDirection: 'row',padding: 5,}}>
          <input
            type="text"
            value={textData}
            onChange={e => setTextdata(e.target.value)}
            style={{borderRadius: 10,width: '80%',marginRight: '2%',border: '1px solid #9c27b0',padding: 5,fontSize: 16,}}
          />
          <Button
              id="icon-button-7"
              buttonType="icon"
              theme="primary"
              type="submit"
              themeType="outline"
              aria-label="Favorite"
              onClick={() => handleSubmit()}
            >
            <FontIcon>search</FontIcon>
          </Button>
        </div>
        <span style={{textAlign: 'left', fontSize: 16,paddingLeft: 10,color: 'red',width: '100%',}}>{errorText}</span>
        <div style={{width: '100%',}}>
          {loading && <CircularProgress id="loading-tab-2" />}
          <List style={{display: 'flex', flexDirection: 'column', flex: 1,}}>
              
              {searching ? data.map(({ graph_id, date, grph_t, graph_unit, y_axis, x_axis, x_datetype, graphType }, index) => (
                <ListItem key={graph_id} id={`simple-item-${graph_id}`} style={{padding: 1,}}>
                    <CustomRow
                        tbDate={date}
                        // searchData={"SearchTag"}
                        tbID={graph_id}
                        tbIndex={index}
                        graphTitle={grph_t}
                        graphUnit={graph_unit}
                        yAxis={y_axis}
                        xAxis={x_axis}
                        xDateTypes={x_datetype}
                        graphType={graphType}
                    />
                </ListItem>
              ))
              : null}
              {/* {loadmore ? <Button onClick={loadMoreFunc} theme={loadingmore ? 'disabled' : 'primary'} themeType="outline"><TextIconSpacing icon={ loadingmore ? (<CircularProgress id={'loadmoregrph-id'} centered={false} />) : (<CachedSVGIcon />)}>Load More</TextIconSpacing></Button> : null} */}
              {loadmore ? <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', margin: 'auto', paddingRight: 5,}}><Button id="icon-button-9" buttonType="icon" theme={loadingmore ? 'disabled' : 'primary'} themeType="contained" aria-label="Load More" onClick={loadMoreFunc} > {loadingmore ? <CircularProgress id={'loadmore-Trending'} centered={false} /> : <CachedSVGIcon />}  </Button></div> : null}
              { visible ? <Button floating="bottom-left" theme="clear" onClick={scrollToTop}><ArrowUpwardSVGIcon /></Button> : null }
          </List>
        </div>
        <Overlay
          id="custom-theme-blog-overlay"
          // onRequestClose={() => displayOverlay(false, 'isBlogOverlay')}
          visible={blogOverlayDisp}
          style={{width: getLayoutWidth(context), overflowY: 'auto',}}
          portal={true}
        >
          <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', paddingTop: '5%', paddingBottom: '5%',overflowY: 'auto',}}>
            <div style={{display: 'flex', flexDirection: "column", width: '90%', margin: 'auto', backgroundColor: 'white', borderRadius: 10, paddingBottom: 5,}}>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: 5,}}>
                <span style={{textAlign: 'center', marginLeft: '5%'}}>Related Blog</span>
                <Button
                  id="Close-blog"
                  buttonType="icon"
                  theme="error"
                  aria-label="Close"
                  onClick={() => displayOverlay(false, 'isBlogOverlay')}
                >
                  <CloseSVGIcon />
                </Button>
              </div>
              
              <List style={{width: '100%', display: 'flex', flexDirection: "column", alignItems: 'flex-start', margin: 0,padding: 0,}}>
                {blogOverlayData.length === 0 ? <CircularProgress id="loading-tab-blog-overlay" /> : blogOverlayData.map((item, index) => (
                  <ListItem key={`'overlayBlog'-${item.titbit_id}-${index}`} style={{padding: 5,}}>
                    <CustomRowBlog
                        title={item.created_by}
                        // description={item.title}
                        // image_url={item.profile_pic}
                        tbDate={item.date}
                        // editedBy={item.admin_edit}
                        tagData={item.tags_list}
                        // tbImage={item.tibits_img}
                        searchData={true}
                        tbID={item.titbit_id}
                        // tbIndex={index}
                        orgData={item.input}
                        sourceUrl={item.source_url}
                        srcVal={item.src}
                        topicData={item.topic_data}
                        bcgColor={item.color}
                        sourceName={item.source_name}
                        site_disc={item.site_disc}
                      />
                </ListItem>
                ))}
              </List>
            </div>
          </div>
        </Overlay>
        <Overlay
          id="custom-theme-text-overlay"
          // onRequestClose={() => displayOverlay(false, 'isTextOverlay')}
          visible={textOverlayDisp}
          style={{width: getLayoutWidth(context), overflowY: 'auto'}}
          portal={true}
        >
          <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', paddingTop: '5%', paddingBottom: '5%',overflowY: 'auto',}}>
            <div style={{display: 'flex', flexDirection: "column", width: '90%', margin: 'auto', backgroundColor: 'white', borderRadius: 10, paddingBottom: 5,}}>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: 5,}}>
                <span style={{textAlign: 'center', marginLeft: '5%'}}>Related Titbits</span>
                <Button
                  id="Close-blog"
                  buttonType="icon"
                  theme="error"
                  aria-label="Close"
                  onClick={() => displayOverlay(false, 'isTextOverlay')}
                >
                  <CloseSVGIcon />
                </Button>
              </div>
              <List style={{display: 'flex', flex: 1, flexDirection: "column", margin: 0,padding: 0,}}>
                {textOverlayData.length === 0 ? <CircularProgress id="loading-tab-text-overlay" /> : textOverlayData.map((item, index) => (
                  <ListItem key={`'overlayText'-${item.titbit_id}-${index}`} style={{padding: 5,}}>
                    <CustomRowText
                        title={item.created_by}
                        description={item.title}
                        image_url={item.profile_pic}
                        // favorite={favourite}
                        numbersData={item.numbers}
                        tbDate={item.date}
                        editedBy={item.admin_edit}
                        tagData={item.tags_list}
                        // navigate={this.props.navigation.navigate}
                        tbImage={item.tibits_img}
                        searchData={true}
                        tbID={item.titbit_id}
                        // tbIndex={index}
                        // userID={this.props.loginDetails.id}
                        orgData={item.input}
                        sourceUrl={item.source_url}
                        srcVal={item.src}
                        bcgColor={item.color}
                        sourceName={item.source_name}
                        graphTitle={item.graphTitle}
                        yearsData={item.years}
                    />
                  </ListItem>
                ))}
              </List>
            </div>
          </div>
        </Overlay>
      </div>
    </div>
  );
};

export default withRouter(SearchGraph);