import React from 'react';
import cn from 'classnames';

import styles from './Container.module.scss';

const Container = ({ children, centered }) => (
  <div
    className={cn(styles.container, {
      [styles.centered]: centered,
    })}
  >
    {children}
  </div>
);

export default Container;