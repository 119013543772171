import React, { FC } from "react";
import {
  BrowserRouter,
  Link,
  Route,
  Switch,
  useLocation,
  Redirect
} from "react-router-dom";
import { Text } from '@react-md/typography';

import { AppSizeListener, useAppSize, useToggle } from '@react-md/utils';

import Home from './Home';
import TagTB from './TagTB';
import TopicSearch from './TopicSearch';
import SearchTB from './SearchTB';
import SearchGraph from './SearchGraph';
import BlogTag from './BlogTag';
import About from "./About";
import Team from "./Team";
import ContactUs from "./ContactUs";


// const navItems = {
//   "/": {
//     itemId: "/",
//     parentId: null,
//     children: "Home",
//     leftAddon: <HomeSVGIcon />,
//     to: "/",
//   },
//   "/route-1": {
//     itemId: "/route-1",
//     parentId: null,
//     children: "Route 1",
//     leftAddon: <HomeSVGIcon />,
//     to: "/route-1",
//   },
//   "/route-2": {
//     itemId: "/route-2",
//     parentId: null,
//     children: "Route 2",
//     leftAddon: <AppsSVGIcon />,
//     to: "/route-2",
//   },
//   "/route-3": {
//     itemId: "/route-3",
//     parentId: null,
//     children: "Route 3",
//     leftAddon: <BookSVGIcon />,
//     to: "/route-3",
//   },
// };

// const Home = () => <Text>Home Page</Text>;
// const Route1 = () => <Text>First Route</Text>;
// const Route2 = () => <Text>Second Route</Text>;
const Error = () => <Text>Oops! Page not found</Text>;

// const App = () => {
//   const { pathname } = useLocation();

//   return (
//     <Layout
//       {...useLayoutNavigation(navItems, window.location.pathname)}
//       title="Example Title"
//       navHeaderTitle="Example Nav Title"
//       treeProps={useLayoutNavigation(navItems, pathname, Link)}
//     >
//       <Switch>
//         <Route path="/route-1" component={Route1} />
//         <Route path="/route-2" component={Route2} />
//         <Route path="/route-3" component={Route3} />
//         <Route path="/" component={Home} />
//       </Switch>
//     </Layout>
//   );
// };



export default function MyApp() {
  const context = useAppSize();

  const getLayoutWidth = (lytdata) => {
    if(lytdata.isPhone){
      return '100%';
    }
    if(lytdata.isTablet){
      return '50%';
    }
    if(lytdata.isDesktop){
      return '40%';
    }
    if(lytdata.isLargeDesktop){
      return '30%';
    }
  }

  return (
    <div style={{width: getLayoutWidth(context)}}>
      <Switch>
         <Route path="/" component={Home} exact />
         <Route path="/tag" component={TagTB} />
         <Route path="/topic" component={TopicSearch} />
         <Route path="/searchTB" component={SearchTB} />
         <Route path="/searchGraph" component={SearchGraph} />
         <Route path="/tagSearch" component={BlogTag} />
         <Route path="/about" component={About} />
         <Route path="/team" component={Team} />
         <Route path="/contactus" component={ContactUs} />
         <Route component={Error} />
      </Switch>
    </div>
  );
}