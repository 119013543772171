import React from 'react';
// import { View, Text, StyleSheet, Image, Linking, TouchableOpacity, CheckBox } from 'react-native';
// import Icon from 'react-native-vector-icons/Ionicons';
import { Text } from '@react-md/typography';
import Moment from 'moment';
import { MediaContainer } from '@react-md/media';
// import { Text, TextContainer } from '@react-md/typography';
import { Divider } from '@react-md/divider';
// import logo from './logo.png';
// import { List, ListItem } from '@react-md/list';
import { Link } from '@react-md/link';
import {
    Link as TagLink
  } from "react-router-dom";
// import Container from './Container';

Moment.locale('en');

// const colorCodes = ["#ffffff", "#5AA0F2", "#59F1C1", "#F259DA", "#F1E459", "#CE7FC3"];

// function getTitle(titleData){
//     var tbtitle = "";
//     if(titleData || titleData !== ""){
//         var tbsptitle = (titleData).split(";");
//         var tmp = tbsptitle[1];
//         tbsptitle[1] = tbsptitle[2];
//         tbsptitle[2] = tmp;
//         // console.log(JSON.stringify(tbsptitle));
//         for (var j = 0; j < tbsptitle.length; j++) {
//             if (tbsptitle[j] === "[Year]" || tbsptitle[j] === "[Region]" || tbsptitle[j] === "[Company]" || tbsptitle[j] === "[Commodity]" || tbsptitle[j] === "[Variable]") {
//                 tbsptitle[j] = "";
//             } else {
//                 // console.log(tbsptitle[j]);
//                 // if(tbsptitle[j].indexOf(',') > -1){
//                     tbsptitle[j] = tbsptitle[j].split(",").join(" ");
//                 // }
                
//             }
//         }
//         tbtitle = tbsptitle.join(" ");
//         return tbtitle;
//     }
    
//     return tbtitle;
// }

// function isFavorite(favData, bcgColor){
//     var fav;
//     if (favData) {
//         fav = "red";
//     } else {
//         fav = getFontColor(bcgColor);
//     }

//     return fav;
// }

// function getNumbers(numbersData, bcgColor){
//     if(numbersData){
//         var titnum = [];
//         if (numbersData === "[Blank]") {
//             return null;
//         } else {
//             var titnum2 = (numbersData).split(";");
//             var p = 0;
//             var q = titnum2.length;
//             for (p = 0; p < q; p++) {
//                 var regex = /[+-]?\d+(?:\.\d+)?/g;
//                 var match = regex.exec(titnum2[p]);
//                 if (match < 0) {
//                     titnum.push({
//                         color: "red",
//                         number: titnum2[p],
//                     })
//                 } else {
//                     titnum.push({
//                         color: getFontColor(bcgColor),
//                         number: titnum2[p],
//                     })
//                 }
//             }
//         }

//         return (
//             titnum.map((item, i) => {
//                 return(
//                     item ? <Text key={i}>{item.number}</Text> : null
//                 )
//             })
//         );
//     }else{
//         return null;
//     }
    
// }

// function getImageData(tbImage){
//     if(tbImage){
//         return(
//             <ModalImage
//                 small={'https://titbitss.pythonanywhere.com'+ tbImage}
//                 large={'https://titbitss.pythonanywhere.com'+ tbImage}
//                 alt="Hello World!"
//             />
//         );
//     }else{
//         return null;
//     }
// }

// function isEditedByAdmin(editedBy){
//     var admnedt;
//     if (editedBy) {
//         admnedt = "Edited by Admin";
//     } else {
//         admnedt = "";
//     }

//     return admnedt;
// }

// function getTagsList(tagslist, searchData){
//     var tgs_list = "";
//     var initialTag = [];
//     for (var j = 0; j < tagslist.length; j++) {
//         var tgs = tagslist[j];
//         tgs = tgs.trim();
//         if (tgs === "" || tgs === " " || tgs === "#" || tgs === "# " || tgs === "  ") {
//         } else {

//             if (tgs.indexOf("#") === -1) {
//                 tgs = "#" + tgs;
//             }
//             if (tgs.includes("[Variable]")) {
//                 var a = tgs.split("[Variable]");
//                 tgs = a.join('');
//             }
//             if (tgs.includes("[Commodity]")) {
//                 var ab = tgs.split("[Commodity]");
//                 tgs = ab.join('');
//             }
//             initialTag.push({'id': j, 'inData': tagslist[j], 'fiData': tgs});
//         }
//     }

    
//     return (
//             initialTag.map(tagInfo => {
//                 return (
//                     searchData ? <span style={{color:'#0000FF', textDecoration: "underline", fontSize: 14, fontWeight: 500,}} key={tagInfo.id}>{tagInfo.fiData}</span> : <span style={{color:'#0000FF', textDecoration: "underline", fontSize: 14,fontWeight: 500,}} key={tagInfo.id}>{tagInfo.fiData}</span>
                    
//                 );
//             })
//     );
// }

function getSourceUrl(Surl, sourceName){
    if(Surl){
        if(Surl.trim() === ""){
            return null;
        }
        return (<span style={{fontSize: 14}}><Link href={Surl} target="_blank">{sourceName ? sourceName : "Read more"}</Link></span>);
        
    }else{
        return null;
    }
    
}

// function getorgData(orginalData){
//     if(orginalData){
//         if(orginalData.trim() === ""){
//             return null;
//         }
//         return (<span style={{whiteSpace: 'pre-wrap', fontSize: 14, color: 'black'}}>{orginalData.replace(/(\n\r|\n|\r)/gm, "")}</span>);
//     }else{
//         return null;
//     }
    
// }

function getSrcdateval(SrcVal){
    if(SrcVal){
        if(SrcVal.trim() === ""){
            return null;
        }else if(SrcVal.indexOf(';') > -1){
            var SrcSplit = SrcVal.split(';');
            return (<span style={{fontSize: 12}}>{Moment(SrcSplit[0]).format('D MMMM yy')}</span>);
        }else{
            return (<span style={{fontSize: 12}}>{Moment(SrcVal).format('D MMMM yy')}</span>);
        }
    }else{
        return null;
    }
}

// function getSrcval(SrcVal, bcgColor){
//     if(SrcVal){
//         if(SrcVal.trim() === ""){
//             return null;
//         }else if(SrcVal.indexOf(';') > -1){
//             var SrcSplit = SrcVal.split(';');
//             return (<Text>{SrcSplit[0]}{"\n"}{SrcSplit[1]}</Text>);
//         }else{
//             return (<Text>{SrcVal}</Text>);
//         }
//     }else{
//         return null;
//     }
// }

function getImageData(tbImage){
    if(tbImage){
        return(
            <MediaContainer fullWidth><img src={tbImage} alt="" /></MediaContainer>
        );
    }else{
        return;
    }
}

function getorgData(orginalData){
    const xorigin = [orginalData.replace(/(\n\r|\n|\r)/gm, "")];
    if(orginalData){
        if(orginalData.trim() == ""){
            return null;
        }
        // return (<Text style={{color: getFontColor(bcgColor), backgroundColor: 'yellow'}}>{orginalData.replace(/(\n\r|\n|\r)/gm, "")}</Text>);
        return(
            <div style={{paddingTop: 5,}}>
                <span>
                {xorigin.map(t=> (
                    <span style={{backgroundColor: 'pink'}}>{t}</span>))}
                </span>
            </div>
        )
    }else{
        return null;
    }
    
}





function TrendingDesign({ title, tbDate, tagData, tbID, orgData, sourceUrl, srcVal, sourceName, site_disc, searchData}){

    return(

      <div style={{display: 'flex', alignItems: 'flex-start', margin: 5, boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)', borderRadius: 5,}}>
        <div style={{display: 'flex', flexDirection: 'column',padding: 5, 'textAlign': "left", whiteSpace: 'pre-wrap',flex: 1}}>
            <div style={{display: 'flex',flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: 20,}}>
                {tagData ? searchData ? <TagLink to={{pathname: '/tagSearch', tagData: tagData[0],}} key={tagData[0]}><span style={{color:'#0000FF', textDecoration: "underline", fontSize: 14,fontWeight: 500,}}>{tagData[0]}</span></TagLink> : <span style={{fontSize: 14,}}>{tagData[0]}</span> : null }
                {srcVal ? getSrcdateval(srcVal) : null}
            </div>
            <Divider />
            {site_disc ? site_disc.title === "" ? null : <span style={{fontSize: 18, fontWeight: 'bold'}}>{site_disc.title}</span> : null}
            {/* { site_disc ? site_disc.image === "" ? null : <MediaContainer fullWidth><img src={site_disc.image} alt="" style={{maxHeight: 150,}} /></MediaContainer> : null } */}
            {site_disc ? site_disc.title == "" ? null : getImageData(site_disc.image) : null}
            {getorgData(orgData)}
            {getSourceUrl(sourceUrl, sourceName)}
            <span style={{color: "#b9b7b7", fontSize: 12}}>{Moment(tbDate).format('D MMMM yy')}</span>
            <span style={{fontSize: 12, textTransform: 'capitalize',}}>Highlighted By - {title}</span>
            {/* <div style={{display: 'flex', width: '100%', flexDirection: 'row', height: '20%', alignItems: 'center', justifyContent: 'flex-start'}}>
                 <Avatar>
                     <img src={image_url} alt="" style={{resizeMode: "cover",}} />
                 </Avatar>
                 <Text style={{marginLeft: 5,}}>{title}</Text>
             </div>
             <div style={{height: '65%', paddingBottom: 5,overflowY: 'auto'}}>
                 {getorgData(orgData, bcgColor)}
                 {getSrcval(srcVal, bcgColor)}
                 {getSourceUrl(sourceUrl, sourceName)}
                 <Text style={{fontSize: 12}}>{Moment(tbDate).format('D MMMM yy')}</Text>
             </div>
             <Divider />
             <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: "center", height: '10%'}}>
                 <img src={logo} alt="TitBits" style={{height: 30,width: 30, resizeMode: "stretch",}} />
             </div> */}
        </div>
      </div>
)};

export default TrendingDesign
