import React, { useEffect, useCallback, useReducer } from 'react';
import { CircularProgress } from '@react-md/progress';
import { TabPanel } from '@react-md/tabs';

import { useSelector, useDispatch, batch } from "react-redux";
import { NomoreData, setDataLoaded, addTopicItems, startLoadmoretopic, setNextTopicItems, endLoadmore, setTopicSelected, setALLSelected, addtbItem, setLoadmore } from './reducer';
import { List, ListItem } from '@react-md/list';
import CustomRow from './TrendingDesign';
import Container from './Container';
// import IndivTopic from './IndivTopic';
import { Chip } from '@react-md/chip';

import { CachedSVGIcon } from '@react-md/material-icons';
import { Button } from '@react-md/button';
// import { AllInclusiveFontIcon } from 'react-md';

const BlogTB = (props) => {
    const dispatch = useDispatch();
    const isDataLoaded = useSelector(state => state.isDataLoadedBlog);
    const topicList = useSelector(state => state.topicList);
    const allData = useSelector(state => state.allBlogData);
    const allSelected = useSelector(state => state.allSelected);
    const allOffset = useSelector(state => state.allBlogOffset);
    const allLoadmore = useSelector(state => state.allBlogLoadmore);
    const allListend = useSelector(state => state.allBlogListEnd);
    const allLoading = useSelector(state => state.allBlogLoading);
    
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect(() => {
        let cancelled = false;
    
        if(!isDataLoaded){
          (async function load() {
            const response = await fetch('https://api.numerokno.com/api/get_blogs/', {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "app-key": "E9FE46D9-FC53-480F-9DC6-D26A7DE233A0"
              },
              body: JSON.stringify({
                userId: 1,
                offset: allOffset,
              })
            });
            const result = await response.json();
      
            if (!cancelled) {
              
              if(result.titbits.length === 0){
                dispatch(endLoadmore({
                    loadingmore: true,
                    loadingmoreName: 'allBlogListEnd',
                    loadmore: false,
                    loadmoreName: 'allBlogLoadmore',
                    loading: false,
                    loadingName: 'allBlogLoading',
                  }));
              }else{

                batch(() => {
                    dispatch(addtbItem({
                        stateName: 'allBlogData',
                        data: result.titbits,
                        loading: false,
                        loadmore: true,
                        startVal: 10,
                        endVal: 20,
                        loadingName: 'allBlogLoading',
                        loadmoreName: 'allBlogLoadmore',
                        startvalName: 'normStart',
                        endvalName: 'normEnd',
                        offsetName: 'allBlogOffset',
                        offsetVal: result.offset,
                      }));
                  dispatch(setDataLoaded({
                    stateName: 'isDataLoadedBlog',
                    dataLoaded: true,
                  }))
                });
              }
            }
      
          })(forceUpdate());

          (async function load() {
            const response = await fetch('https://api.numerokno.com/api/topic_manage/', {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                func: 4
              })
            });
            const result = await response.json();
      
              
              if(result.length === 0){
                dispatch(NomoreData({
                  blogLoadingmore: false,
                  blogLoadingmorename: 'blogLoadingmore1',
                  blogLoadingname: 'blogLoading1',
                  blogLoading: false,
                }));
              }else{
                let NewBlogdata = result;
                NewBlogdata.map((item) => {
                  // var temp = Object.assign({}, item);
                  item.topicData = [];
                  item.loadmore = false;
                  item.startVal = 0;
                  item.endVal = 10;
                  item.listEnd = false;
                  item.offset = 0;
                  item.dataloading = true;
                  item.selected = false;
                  // return temp;
                  return item;
                });
                
                
                batch(() => {
                  dispatch(addTopicItems(NewBlogdata));
                //   dispatch(setDataLoaded({
                //     stateName: 'isDataLoadedBlog',
                //     dataLoaded: true,
                //   }))
                });
              }
      
          })();
          
        }
    
        return () => {
          cancelled = true;
        };
      }, [dispatch, isDataLoaded, allOffset]);

      const loadMoreAllData = useCallback(
        () => {
          dispatch(setLoadmore({
            loadingmore: true,
            loadingmoreName: 'allBlogLoading',
          }));
          forceUpdate();

          (async function load() {
            const response = await fetch('https://api.numerokno.com/api/get_blogs/', {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "app-key": "E9FE46D9-FC53-480F-9DC6-D26A7DE233A0"
              },
              body: JSON.stringify({
                userId: 1,
                offset: allOffset,
              })
            });
            const result = await response.json();
              
              if(result.titbits.length === 0){
                dispatch(endLoadmore({
                    loadingmore: true,
                    loadingmoreName: 'allBlogListEnd',
                    loadmore: false,
                    loadmoreName: 'allBlogLoadmore',
                    loading: false,
                    loadingName: 'allBlogLoading',
                  }));
              }else{

                batch(() => {
                    dispatch(addtbItem({
                        stateName: 'allBlogData',
                        data: result.titbits,
                        loading: false,
                        loadmore: true,
                        startVal: 10,
                        endVal: 20,
                        loadingName: 'allBlogLoading',
                        loadmoreName: 'allBlogLoadmore',
                        startvalName: 'normStart',
                        endvalName: 'normEnd',
                        offsetName: 'allBlogOffset',
                        offsetVal: result.offset,
                      }));
                });
              }
      
          })(forceUpdate());
        }, [dispatch, allOffset]
      )

      const loadmoretopic = useCallback(
        (indexval, offset, topicId) => {
          // setLoadingMore(true);
          // console.log(indexval, offset, topicId)
          dispatch(startLoadmoretopic({
            indexValue: indexval,
            action: true,
          }))
          forceUpdate();
          // console.log(topicId, offset);
          (async function load() {
            const response = await fetch('https://api.numerokno.com/api/topic_titbits/', {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'APP-KEY': "E9FE46D9-FC53-480F-9DC6-D26A7DE233A0",
              },
              body: JSON.stringify({
                userId: 1,
                topic: topicId,
                offset: offset,
              })
            });
            const result = await response.json();
              if(result.titbits.length === 0){
                dispatch(startLoadmoretopic({
                  indexValue: indexval,
                  action: false,
                }))
              }else{
                dispatch(setNextTopicItems({
                  data: result.titbits,
                  indexValue: indexval,
                  startValue: 10,
                  endValue: 20,
                  offset: result.offset
                }))
              }
              forceUpdate();
    
          })(forceUpdate());
        }, [dispatch]
      )

      const setAllSelected = useCallback(
          () => {
            //   console.log(JSON.stringify(topicList))
            dispatch(setALLSelected());
            forceUpdate();
          },[dispatch],
      )

      const setSelectedTopic = useCallback(
        (topicId, index) => {
            // console.log(JSON.stringify(topicList));
            // setLoadingmore(true);
            dispatch(setTopicSelected({
                index: index,
                topicId: topicId,
            }));
            forceUpdate();
            // startVal = endVal;
            // endVal = endVal + 10;
            if(topicList[index].dataloading){
                (async function load() {
                    const response = await fetch('https://api.numerokno.com/api/topic_titbits/', {
                      method: 'POST',
                      headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        "app-key": "E9FE46D9-FC53-480F-9DC6-D26A7DE233A0"
                      },
                      body: JSON.stringify({
                          userId: 1,
                          topic: topicId,
                          offset: 0,
                      })
                    });
                    const result = await response.json();
                  //   console.log(JSON.stringify(result))
                    if(result.titbits.length === 0){
                      dispatch(startLoadmoretopic({
                        indexValue: index,
                        action: false,
                      }))
                    }else{
                      dispatch(setNextTopicItems({
                        data: result.titbits,
                        indexValue: index,
                        startValue: 10,
                        endValue: 20,
                        offset: result.offset
                      }))
                    }
                    forceUpdate();
                  })(forceUpdate());
            }
            
        },
        [dispatch, topicList],
      );

    function Item(props){
        const itemData = props.value;
        return (
            <ListItem key={`${itemData.titbit_id}-${props.indexval}`} style={{margin: 1,padding: 5,}}>
                <CustomRow
                        title={itemData.created_by}
                        // description={item.title}
                        // image_url={item.profile_pic}
                        tbDate={itemData.date}
                        // editedBy={item.admin_edit}
                        tagData={itemData.tags_list}
                        // tbImage={item.tibits_img}
                        searchData={true}
                        tbID={itemData.titbit_id}
                        // tbIndex={index}
                        orgData={itemData.input}
                        sourceUrl={itemData.source_url}
                        srcVal={itemData.src}
                        topicData={itemData.topic_data}
                        bcgColor={itemData.color}
                        sourceName={itemData.source_name}
                        site_disc={itemData.site_disc}
                    />
            </ListItem>
        )
    }

    function allDataList() {
        return(
            <List style={{width: '100%', display: 'flex', flexDirection: "column", alignItems: 'flex-start', margin: 0,padding: 0,}}>
                {allData.map((item, index) => <Item key={'allBlog' + index} value={item} indexval={index} />)}
                
                {!allListend ? <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', margin: 'auto', paddingRight: 5,}}><Button id={`icon-button-allData`} buttonType="icon" theme={allLoading ? 'disabled' : 'primary'} themeType="contained" aria-label="Load More" onClick={()=> loadMoreAllData()}> {allLoading ? <CircularProgress centered={false} id={`cp-allData`} /> : <CachedSVGIcon />}  </Button></div> : null}
            </List>
        )
    }


    return (
        <TabPanel {...props}>
          <Container centered>
            <div style={{width: '100%', display: 'flex', flex: 1, flexDirection: "column", alignItems: 'flex-start',}}>
                {!isDataLoaded ? <CircularProgress id="loading-tab-2" /> : <div style={{width: '100%', display: 'flex', flexDirection: 'column',}}><div style={{display: 'flex', flexDirection: "row", alignItems: 'flex-start', overflowX: 'auto', padding: 5, marginBottom: 5,}}>
                    <Chip style={{marginRight: 2, marginBottom: 2}} selectedThemed selected={allSelected} onClick={() => setAllSelected()}>All</Chip>
                    {topicList.map(({Topic_name, topicId, selected}, index) =>{
                        return(
                            <Chip style={{marginRight: 2, marginBottom: 2}} selectedThemed selected={selected}  key={'topicsbubble' + index} onClick={() => setSelectedTopic(topicId, index)}>{Topic_name}</Chip>
                        )
                    })}
                </div>
                <div style={{width: '100%', display: 'flex',}}>
                    {allSelected ? allDataList() : topicList.map((item, index) => {
                        if(item.selected){
                            if(item.dataloading){
                               return(
                                 <div style={{display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}><CircularProgress id="loading-tab-2" /></div>
                               )
                            }
                            return(
                                <List style={{display: 'flex', flexDirection: "column", alignItems: 'flex-start', margin: 0,padding: 0,}}>
                                    {item.topicData.map((item, index) => <Item key={'topicBlog' + index} value={item} indexval={index} />)}

                                    {!item.listEnd ? <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', margin: 'auto', paddingRight: 5,}}><Button id={`icon-button-allData`} buttonType="icon" theme={item.loadmore ? 'disabled' : 'primary'} themeType="contained" aria-label="Load More" onClick={()=> loadmoretopic(index, item.offset, item.topicId)}> {item.loadmore ? <CircularProgress centered={false} id={`cp-allData`} /> : <CachedSVGIcon />}  </Button></div> : null}
                                </List>
                            )
                        }
                        return null;
                    })   }
                </div></div> }
            </div>
            
          </Container>
        </TabPanel>
    )
}

export default BlogTB;