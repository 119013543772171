// import React from 'react';
// import ReactDOM from 'react-dom';
// import { Configuration, ConfigurationProps } from 'react-md';
// import './index.css';
// import App from './App';
// // import * as serviceWorker from './serviceWorker';

// ReactDOM.render(
//   <Configuration>
//     <App />
//   </Configuration>,
//   document.getElementById('root')
// );

// import "./styles.scss";
import React from "react";
import { render } from "react-dom";
import { Configuration } from "@react-md/layout";
import { BrowserRouter } from "react-router-dom";
import store from "./store";
import { Provider } from "react-redux";

import APP from "./App";

render(
  <Provider store={store}>
    <BrowserRouter>
      <Configuration>
        <APP />
      </Configuration>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);