import React from 'react';
import Moment from 'moment';
// import ModalImage from 'react-modal-image';
import { Collapse } from '@react-md/transition';
// import { Card, CardHeader, CardTitle, CardSubtitle, CardContent } from '@react-md/card';
// import { Avatar } from '@react-md/avatar';
import { useToggle } from '@react-md/utils';
import { Button } from '@react-md/button';
import { IconRotator } from '@react-md/icon';
import {
//   PhoneSVGIcon,
//   EmailSVGIcon,
  KeyboardArrowDownSVGIcon,
} from '@react-md/material-icons';
// import { List, ListItem } from '@react-md/list';
// import { MediaContainer } from '@react-md/media';
import { Text, TextContainer } from '@react-md/typography';
import { Divider } from '@react-md/divider';
import logo from './logo.png';
import user from './user.png';
import { Link } from '@react-md/link';
import {
    Link as TagLink
  } from "react-router-dom";
import { MediaContainer } from '@react-md/media';
// import { usePanels, ExpansionPanel } from '@react-md/expansion-panel';


Moment.locale('en');

const colorCodes = ["#ffffff", "#5AA0F2", "#59F1C1", "#F259DA", "#F1E459", "#CE7FC3"];

function getTitle(titleData){
    var tbtitle = "";
    if(titleData || titleData !== ""){
        var tbsptitle = (titleData).split(";");
        var tmp = tbsptitle[1];
        tbsptitle[1] = tbsptitle[2];
        tbsptitle[2] = tmp;
        // console.log(JSON.stringify(tbsptitle));
        for (var j = 0; j < tbsptitle.length; j++) {
            if (tbsptitle[j] === "[Year]" || tbsptitle[j] === "[Region]" || tbsptitle[j] === "[Company]" || tbsptitle[j] === "[Commodity]" || tbsptitle[j] === "[Variable]") {
                tbsptitle[j] = "";
            } else {
                // console.log(tbsptitle[j]);
                // if(tbsptitle[j].indexOf(',') > -1){
                    tbsptitle[j] = tbsptitle[j].split(",").join(" ");
                // }
                
            }
        }
        tbtitle = tbsptitle.join(" ");
        return tbtitle;
    }
    
    return tbtitle;
}

function isFavorite(favData, bcgColor){
    var fav;
    if (favData) {
        fav = "red";
    } else {
        fav = getFontColor(bcgColor);
    }

    return fav;
}

function getNumbers(numbersData, bcgColor){
    if(numbersData){
        var titnum = [];
        if (numbersData === "[Blank]") {
            return null;
        } else {
            var titnum2 = (numbersData).split(";");
            var p = 0;
            var q = titnum2.length;
            for (p = 0; p < q; p++) {
                var regex = /[+-]?\d+(?:\.\d+)?/g;
                var match = regex.exec(titnum2[p]);
                if (match < 0) {
                    titnum.push({
                        color: "red",
                        number: titnum2[p],
                    })
                } else {
                    titnum.push({
                        color: "black",
                        number: titnum2[p],
                    })
                }
            }
        }

        return (
            titnum.map((item, i) => {
                return(
                    item ? <span key={i} style={{color: item.color, marginRight: 2, fontSize: 18,}}>{item.number}</span> : null
                )
            })
        );
    }else{
        return null;
    }
    
}

function getImageData(tbImage){
    if(tbImage){
        return(
            // <ModalImage
            //     small={'https://titbitss.pythonanywhere.com'+ tbImage}
            //     large={'https://titbitss.pythonanywhere.com'+ tbImage}
            //     alt="Hello World!"
            // />
            <MediaContainer fullWidth><img src={tbImage} alt="" /></MediaContainer>
        );
    }else{
        return null;
    }
}

function isEditedByAdmin(editedBy){
    var admnedt;
    if (editedBy) {
        admnedt = "Edited by Admin";
    } else {
        admnedt = "";
    }

    return admnedt;
}

function getTagsList(tagslist, searchData){
    var tgs_list = "";
    var initialTag = [];
    for (var j = 0; j < tagslist.length; j++) {
        var tgs = tagslist[j];
        tgs = tgs.trim();
        if (tgs === "" || tgs === " " || tgs === "#" || tgs === "# " || tgs === "  ") {
        } else {

            if (tgs.indexOf("#") === -1) {
                tgs = "#" + tgs;
            }
            if (tgs.includes("[Variable]")) {
                var a = tgs.split("[Variable]");
                tgs = a.join('');
            }
            if (tgs.includes("[Commodity]")) {
                var ab = tgs.split("[Commodity]");
                tgs = ab.join('');
            }
            initialTag.push({'id': j, 'inData': tagslist[j], 'fiData': tgs});
        }
    }

    
    return (
            initialTag.map(tagInfo => {
                return (
                    searchData ? <TagLink to={{pathname: '/tag', tagData: tagInfo.fiData,}} key={tagInfo.id}><span style={{color:'#0000FF', textDecoration: "underline", fontSize: 14,fontWeight: 500,}}>{tagInfo.fiData}</span></TagLink> : <span style={{color:'#0000FF', textDecoration: "underline", fontSize: 14,fontWeight: 500,}} key={tagInfo.id}>{tagInfo.fiData}</span>
                );
            })
    );
}

// function getSourceUrl(Surl, sourceName){
//     if(Surl){
//         if(Surl.trim() === ""){
//             return null;
//         }
//         return (<span style={{color:'cornflowerblue'}}><a href={Surl} target={'_blank'}>{sourceName}</a></span>);
//     }else{
//         return null;
//     }
    
// }

function getorgData(orginalData, bcgColor){
    if(orginalData){
        if(orginalData.trim() === ""){
            return null;
        }
        return (<span style={{whiteSpace: 'pre-wrap', margin: 2,fontSize: 14,}}>{orginalData.replace(/(\n\r|\n|\r)/gm, "")}</span>);
    }else{
        return null;
    }
    
}

function getSrcval(SrcVal, bcgColor){
    if(SrcVal){
        if(SrcVal.trim() === ""){
            return null;
        }else if(SrcVal.indexOf(';') > -1){
            var SrcSplit = SrcVal.split(';');
            return (<span style={{whiteSpace: 'pre-wrap', margin: 2,fontSize: 14,}}>{Moment(SrcSplit[0]).format('D MMMM yy')}{"\n"}{SrcSplit[1]}</span>);
        }else{
            return (<span style={{whiteSpace: 'pre-wrap', margin: 2,fontSize: 14,}}>{Moment(SrcVal).format('D MMMM yy')}</span>);
        }
    }else{
        return null;
    }
}

function getprofilePic(url){
    if(url){
        return { uri: url };
    }
    return require('./user.png');
}

function getFontColor(fclr){
    if(fclr === 0 || fclr === 4) return 'black';
    return 'white';
}

function getTitleFontColor(fclr){
    if(fclr === 0 || fclr === 4) return 'grey';
    return 'white';
}

// function getbyData(image_url, title){
//     return(
//         <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',}}>
//             <img src={image_url} alt="" style={{width: '2rem', height: '2rem', borderRadius: '50%'}} />
//             <span style={{marginLeft: 5,}}>{title}</span>
//         </div>
//     )
// }

function getSourceUrl(Surl, sourceName){
    if(Surl){
        if(Surl.trim() === ""){
            return null;
        }
        return (<span><Link href={Surl} target="_blank">{sourceName}</Link></span>);
        
    }else{
        return null;
    }
    
}




function TBDesign({ title, description, image_url, numbersData, tbDate, editedBy, tagData, tbImage, searchData, tbID, orgData, sourceUrl, srcVal, bcgColor, sourceName, graphTitle, yearsData }){
    
    // const [expanded, setExpanded] = React.useState(false);
    const [expanded, , , toggle] = useToggle(false);
    // const [fontColor, setFontColor] = React.useState('black');
    // const [favColor, setFavColor] = React.useState('black');
    // const [backColor, setBackColor] = React.useState('White');
    // console.log(viewShotRef && viewShotRef.current)
    // const expid = 'expanel'+tbID;

    return(

        <div style={{display: 'flex', flexDirection: 'column', boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)', borderRadius: 5, margin: 5,}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: 5,}}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',}}>
                    {image_url ? <img src={image_url} alt="" style={{width: '2rem', height: '2rem', borderRadius: '50%'}} /> : <img src={user} alt="" style={{width: '2rem', height: '2rem', borderRadius: '50%'}} />}
                    <span style={{marginLeft: 5,}}>{title}</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center',}}>
                    {/* <KeyboardArrowDownSVGIcon /> */}
                    <Button
                        id="expand-card-button"
                        onClick={toggle}
                        buttonType="icon"
                        aria-label="Expand"
                        theme="clear"
                        >
                        <IconRotator rotated={expanded}>
                            <KeyboardArrowDownSVGIcon />
                        </IconRotator>
                    </Button>
                </div>
            </div>
            <Collapse collapsed={!expanded}>
                <div style={{display: 'flex', flexDirection: 'column','textAlign': "left", margin: 5, borderRadius: 5, padding: 5, border: 'solid 1px #d3d3d3',}}>
                    <span style={{padding: 5,}}>Original Text</span>
                    <Divider />
                    {getorgData(orgData, bcgColor)}
                    {getSrcval(srcVal, bcgColor)}
                    {getSourceUrl(sourceUrl, sourceName)}
                </div>
            </Collapse>
            {/* <ExpansionPanel
                id={expid}
                expanded={expanded}
                onExpandClick={toggle}
                header={getbyData(image_url, title)}
                theme='clear'
            >
                <Text>
                Suspendisse malesuada vitae ipsum quis faucibus. Interdum et malesuada
                fames ac ante ipsum primis in faucibus.
                </Text>
            </ExpansionPanel> */}
            <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left', padding: 5,whiteSpace: 'pre-wrap'}}>
                {/* <span style={{fontSize: 12, color: 'grey'}}>{getTitle(description)}</span> */}
                <span style={{fontSize: 12, color: 'grey'}}>{graphTitle == "" || !graphTitle ? description ? description : null : yearsData + " " + graphTitle}</span>
                
                <div>
                    {getNumbers(numbersData, bcgColor)}
                </div>
                {getImageData(tbImage)}
                <span style={{fontSize: 12, textAlign: 'right'}}>{Moment(tbDate).format('D MMMM yy')}</span>
            </div>
            <Divider />
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: 5,}}>
                {getTagsList(tagData, searchData)}
                <img src={logo} alt="TitBits" style={{height: 30,width: 30, resizeMode: "stretch",}} />
            </div>
        </div>
    
    //   <Card style={{width: '100%',boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)',}}>
    //     { site_disc.website ? <MediaContainer fullWidth><img src={site_disc.image} alt="" style={{maxHeight: 200,}} /></MediaContainer> : null }
    //     <CardContent style={{padding: 5,'textAlign': "left",}}>
    //         <Text style={{color: "#b9b7b7", fontSize: 12}}>Intresting Fact From The Article</Text>
    //         <TextContainer style={{'textAlign': "left", padding: 5, backgroundColor: "#ebebeb", marginTop: 5, marginBottom: 5, borderRadius: 5, "white-space": 'pre-wrap'}}>
    //             <div style={{display: 'flex', justifyContent: "flex-end", alignItems: "center"}}>
    //                 {/* <span>{title}</span> */}
    //                 <Button
    //                     id="expand-card-button"
    //                     onClick={toggle}
    //                     buttonType="icon"
    //                     aria-label="Expand"
    //                     theme="clear"
    //                     >
    //                     <IconRotator rotated={expanded}>
    //                         <KeyboardArrowDownSVGIcon />
    //                     </IconRotator>
    //                 </Button>
    //             </div>
    //             {/* <Divider />   */}
    //             <Collapse collapsed={!expanded}>
    //                 <TextContainer style={{'textAlign': "left", marginTop: 5, marginBottom: 5, borderRadius: 5, backgroundColor: 'white',paddingTop: 10, paddingBottom: 10,}}>
    //                     <span style={{alignItems: 'center',marginTop: 5, marginBottom: 5,}}>Original Text</span>
    //                     <Divider />
    //                     {getorgData(orgData, bcgColor)}
    //                     {getSrcval(srcVal, bcgColor)}
    //                 </TextContainer>
    //             </Collapse>
    //             <Text>{getTitle(description)}</Text>
    //             {getNumbers(numbersData, bcgColor)}
    //             {getImageData(tbImage)}
    //             <Divider />
    //             {getTagsList(tagData, searchData)}
    //         </TextContainer>
    //         <Text style={{color: "#b9b7b7", fontSize: 12}}>Tap for more at {getSourceUrl(sourceUrl, sourceName)}</Text>
    //         <Text style={{color: "#b9b7b7", fontSize: 12}}>TitBits by {title} - {Moment(tbDate).format('D MMMM yy')}</Text>
    //     </CardContent>
    //   </Card>
)};

export default TBDesign
