import React, {useState} from 'react';
// import logo from './logo.svg';
import { Layout, useLayoutNavigation } from 'react-md';
import './App.css';
import './App.scss';
import { TabsManager, Tabs, TabPanels } from '@react-md/tabs';
import { AppBar, AppBarNav, AppBarTitle, AppBarAction, } from '@react-md/app-bar';
import {
  BrowserRouter,
  Link,
  Route,
  Switch,
  useLocation,
  Redirect
} from "react-router-dom";
import {
  ArtTrackSVGIcon,
  // TrendingUpSVGIcon,
  // EqualizerSVGIcon,
  MenuSVGIcon,
  ArrowUpwardSVGIcon,
  // FormatListBulletedSVGIcon,
  ListSVGIcon,
  CloseSVGIcon,
  AppsSVGIcon,
  HomeSVGIcon,
  InfoSVGIcon,
  DescriptionSVGIcon,
  ContactMailSVGIcon,
  GroupSVGIcon,
  LockSVGIcon,
  InsertChartSVGIcon
} from '@react-md/material-icons';

import { Button } from "@react-md/button";

import { AppSizeListener, useAppSize, useToggle } from '@react-md/utils';
import { Sheet } from '@react-md/sheet';
import { DialogContent } from '@react-md/dialog';
import { List, ListItem } from '@react-md/list';

import { useSelector, useDispatch} from "react-redux";
import { setActiveIndex } from './reducer';

import { withRouter, useHistory } from "react-router-dom";

import Content3 from './GraphTB';
import Content2 from './NormalTB';
// import Content1 from './TrendingTB';
import Content1 from './BlogTB';

import logo from './logo.png';
// import { FALSE } from 'node-sass';

const tabs = [
  { children: <ArtTrackSVGIcon /> },
  { children: <ListSVGIcon /> },
  { children: <InsertChartSVGIcon /> },
];

// const navItems = {};

 function Home(props) {
  const context = useAppSize();
  const [visibleMenu, show, hide] = useToggle(false);
  const [visible, setVisible] = useState(false);

  const dispatch = useDispatch();
  const activeIndex = useSelector(state => state.activeIndex);

  const toggleVisible = () => { 
    const scrolled = document.documentElement.scrollTop; 
    if (scrolled > 0){ 
      setVisible(true) 
    }  
    else if (scrolled <= 0){ 
      setVisible(false) 
    } 
  };

  const scrollToTop = () =>{ 
    window.scrollTo({ 
      top: 0, 
      behavior: 'auto'
      /* you can also use 'auto' behaviour 
         in place of 'smooth' */
    }); 
  }; 

  window.addEventListener('scroll', toggleVisible);

  const getLayoutWidth = (lytdata) => {
    if(lytdata.isPhone){
      return '100%';
    }
    if(lytdata.isTablet){
      return '50%';
    }
    if(lytdata.isDesktop){
      return '40%';
    }
    if(lytdata.isLargeDesktop){
      return '30%';
    }
  }

  const onActiveIndexChange = (indexData) => {
    // console.log(indexData);
    dispatch(setActiveIndex({
      index: indexData,
    }))
  }

  const navItems = {
    "/": {
      itemId: "/",
      parentId: null,
      children: "Home",
      leftAddon: <HomeSVGIcon />,
      to: "/",
    },
    "/searchTB": {
      itemId: "/searchTB",
      parentId: null,
      children: "Topic",
      leftAddon: <HomeSVGIcon />,
      to: "/searchTB",
    },
    "/searchGraph": {
      itemId: "/searchGraph",
      parentId: null,
      children: "Graph",
      leftAddon: <AppsSVGIcon />,
      to: "/searchGraph",
    },
  };

  let history = useHistory();

   return (
     <div>
      {/* <Layout appBarProps={{ fixed: false }}
        // {...useLayoutNavigation(navItems, window.location.pathname)}
        // appBarTitle="react-md App"
        title="TitBits."
        navHeaderTitle="My App"
        phoneLayout={'temporary'}
        tabletLayout={'temporary'}
        landscapeTabletLayout={'temporary'}
        desktopLayout={'temporary'}
        // treeProps={useLayoutNavigation(navItems, window.location.pathname, Link)}
      > */}
      <AppBar theme="clear" height="dense">
        <AppBarNav id="homeToolBar" aria-label="Navigation" onClick={show}>
          <MenuSVGIcon />
        </AppBarNav>
        <AppBarTitle
          style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
        >
          <img src={logo} alt="TitBits" style={{height: 50,width: 50, resizeMode: "stretch",}} onClick={() => {window.location.reload()}} />
        </AppBarTitle>
      </AppBar>
        <div className="App">
          <TabsManager stacked tabs={tabs} tabsId="persistent-tabs" activeIndex={activeIndex} onActiveIndexChange={onActiveIndexChange}>
            <AppBar theme="clear" height="none">
              <Tabs align={'center'} />
            </AppBar>
            <TabPanels persistent={true}>
              <Content1 />
              <Content2 />
              <Content3 />
            </TabPanels>
          </TabsManager>
          { visible ? <Button floating="bottom-left" theme="clear" onClick={scrollToTop}><ArrowUpwardSVGIcon /></Button> : null }
        </div>
        
        <Sheet
          id="home-menu"
          aria-label="Menu Sheet"
          position={'left'}
          horizontalSize={'media'}
          verticalSize={'recommended'}
          visible={visibleMenu}
          onRequestClose={hide}
          disableScrollLock={false}
        >
          <DialogContent>
            <div style={{display: 'flex', justifyContent: 'right'}}>
              <Button
                id="menu-close"
                buttonType="icon"
                theme="clear"
                aria-label="Add"
                onClick={hide}
              >
                <CloseSVGIcon />
              </Button>
            </div>
            <List onClick={hide}>
              <ListItem id="menu-home" leftAddon={<HomeSVGIcon />}>Home</ListItem>
              <ListItem id="menu-about" leftAddon={<InfoSVGIcon />} onClick={() => history.push({pathname: '/about',})}>About</ListItem>
              <ListItem id="menu-team" leftAddon={<GroupSVGIcon />}>Team</ListItem>
              <ListItem id="menu-contactus" leftAddon={<ContactMailSVGIcon />}>Contact Us</ListItem>
              <ListItem id="menu-privacy" leftAddon={<LockSVGIcon />}>Privacy Policy</ListItem>
              <ListItem id="menu-terms" leftAddon={<DescriptionSVGIcon />}>Terms and Conditions</ListItem>
            </List>
          </DialogContent>
        </Sheet>
      {/* </Layout> */}
    </div>
   );
 }

 export default withRouter(Home);