import React, { useEffect, useState, useCallback } from 'react';
// import logo from './logo.svg';
// import { Layout, useLayoutNavigation } from 'react-md';
import './App.css';
import './App.scss';
import { AppBar, AppBarNav, AppBarTitle, AppBarAction, } from '@react-md/app-bar';
import {
  ArrowBackSVGIcon,
  ArrowUpwardSVGIcon
} from '@react-md/material-icons';

import { AppSizeListener, useAppSize, useToggle } from '@react-md/utils';
import { List, ListItem } from '@react-md/list';
import { CircularProgress } from '@react-md/progress';
import CustomRow from './TbDesign';
import Container from './Container';
import { CachedSVGIcon } from '@react-md/material-icons';
// import { TextIconSpacing } from '@react-md/icon';
import { Button } from '@react-md/button';

import { withRouter } from "react-router-dom";

import logo from './logo.png';


// const navItems = {};

 function TopicSearch(props) {
  const context = useAppSize();

  const getLayoutWidth = (lytdata) => {
    if(lytdata.isPhone){
      return '100%';
    }
    if(lytdata.isTablet){
      return '50%';
    }
    if(lytdata.isDesktop){
      return '40%';
    }
    if(lytdata.isLargeDesktop){
      return '30%';
    }
  }

  const [loadingt, setLoadingt] = useState(false);
  const [datat, setDatat] = useState([]);
  const [loadmoret, setLoadmoret] = useState(false);

  const [loadingmoret, setLoadingmoret] = useState(false);

  const [start_valt, incStartt] = useState(0);
  const [end_valt, incEndt] = useState(10);
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => { 
    const scrolled = document.documentElement.scrollTop; 
    if (scrolled > 0){ 
      setVisible(true) 
    }  
    else if (scrolled <= 0){ 
      setVisible(false) 
    } 
  };

  const scrollToTop = () =>{ 
    window.scrollTo({ 
      top: 0, 
      behavior: 'auto'
      /* you can also use 'auto' behaviour 
         in place of 'smooth' */
    }); 
  }; 

  window.addEventListener('scroll', toggleVisible);

  useEffect(() => {
    let cancelled = false;
    console.log(props);
    // setState({ loading: true, data: [], loadmore: false, });
    setLoadingt(true);
    const { tagData } = props.location;
    var searchWord = tagData;
    if (searchWord.indexOf("#") != -1) {
        searchWord = searchWord.replace("#", "");
    }
    
    (async function load() {
      const response = await fetch('https://titbitss.pythonanywhere.com/api/topic_titbits/', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user_id: 11,
          topic: searchWord,
          start_value: 0,
          end_value: 10
        })
      });
      const result = await response.json();
      const data = result;

      if (!cancelled) {
        setDatat(data);
        setLoadingt(false);
        setLoadmoret(true);
        incStartt(10);
        incEndt(20);
      }
    })();

    return () => {
      cancelled = true;
    };
  }, []);

  const loadFunc = useCallback(
    () => {
        // console.log("end reached");
        setLoadingmoret(true);
        const { tagData } = props.location;
        var searchWord = tagData;
        if (searchWord.indexOf("#") != -1) {
            searchWord = searchWord.replace("#", "");
        }
        // startVal = endVal;
        // endVal = endVal + 10;
        (async function load() {
          const response = await fetch('https://titbitss.pythonanywhere.com/api/topic_titbits/', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              user_id: 11,
              topic: searchWord,
              start_value: start_valt,
              end_value: end_valt
            })
          });
          const result = await response.json();
          
            setDatat(prevState => ([...prevState, ...result]));
            setLoadingmoret(false);
            incStartt(end_valt);
            incEndt(end_valt + 10);

        })();
    },
    [start_valt, end_valt],
  );

   return (
     <div>
      <AppBar theme="clear" height="none">
        <AppBarNav id="TagBack" aria-label="Back" onClick={props.history.goBack}>
          <ArrowBackSVGIcon />
        </AppBarNav>
        <AppBarTitle
          style={{display: 'flex', alignItems: 'center',}}
        >
          {props.location.tagData}
        </AppBarTitle>
      </AppBar>
      <div>
        {/* <InfiniteScroll
                  pageStart={0}
                  loadMore={loadFunc}
                  hasMore={true}
                  loader={<div className="loader" key={0}>Loading ...</div>}
                  useWindow={false}
              > */}
          <List style={{display: 'flex', flex: 1,flexDirection: 'column'}}>
              
              {loadingt ? <CircularProgress id="loading-tab-2" /> : datat.map(({ profile_pic, titbit_id, created_by, title, numbers, date, admin_edit, tags_list, tibits_img, input, source_url, src, color, source_name, }) => (
                <ListItem key={titbit_id} id={`simple-item-${titbit_id}`} style={{padding: 1,}}>
                  <CustomRow
                      title={created_by}
                      description={title}
                      image_url={profile_pic}
                      // favorite={favourite}
                      numbersData={numbers}
                      tbDate={date}
                      editedBy={admin_edit}
                      tagData={tags_list}
                      // navigate={this.props.navigation.navigate}
                      tbImage={tibits_img}
                      searchData={false}
                      tbID={titbit_id}
                      // tbIndex={index}
                      // userID={this.props.loginDetails.id}
                      orgData={input}
                      sourceUrl={source_url}
                      srcVal={src}
                      bcgColor={color}
                      sourceName={source_name}
                  />
                </ListItem>
              ))
              }
              {/* {loadmoret ? <Button onClick={loadFunc} theme={loadingmoret ? 'disabled' : 'primary'} themeType="outline"><TextIconSpacing icon={ loadingmoret ? (<CircularProgress id={'loadmoretopic-id'} centered={false} />) : (<CachedSVGIcon />)}>Load More</TextIconSpacing></Button> : null} */}
              {loadmoret ? <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', margin: 'auto', paddingRight: 5,}}><Button id="icon-button-9" buttonType="icon" theme={loadingmoret ? 'disabled' : 'primary'} themeType="contained" aria-label="Load More" onClick={loadFunc} > {loadingmoret ? <CircularProgress id={'loadmore-Trending'} centered={false} /> : <CachedSVGIcon />}  </Button></div> : null}
              { visible ? <Button floating="bottom-left" theme="clear" onClick={scrollToTop}><ArrowUpwardSVGIcon /></Button> : null }
          </List>
        {/* </InfiniteScroll> */}
      </div>
    </div>
   );
 }

 export default withRouter(TopicSearch);