export const ADD_ITEM = 'ADD_ITEM';
export const LOAD_MORE = 'LOAD_MORE';
export const LOADING_MORE = 'LOADING_MORE';
export const NOMORE_DATA = 'NOMORE_DATA';
export const ADD_TB_DATA = 'ADD_TB_DATA';
export const SET_LOADMORE = 'SET_LOADMORE';
export const ADD_MORETB_DATA = 'ADD_MORETB_DATA';
export const END_LOADMORE = 'END_LOADMORE';
export const ADD_TOPIC = 'ADD_TOPIC';
export const SET_DATALOADED = 'SET_DATALOADED';
export const ADD_TOPIC_LIST = 'ADD_TOPIC_LIST';
export const START_LOADMORE = 'START_LOADMORE';
export const SET_TOPICDATA_NEXT = 'SET_TOPICDATA_NEXT';
export const SET_ACTIVE_INDEX = 'SET_ACTIVE_INDEX';
export const SET_SELECT_TOPIC = 'SET_SELECT_TOPIC';
export const SET_SELECT_ALL = 'SET_SELECT_ALL';
export const SET_OVERLAY = 'SET_OVERLAY';
export const SET_OVERLAY_DATA = 'SET_OVERLAY_DATA';

export const setDataLoaded = item => ({
    type: SET_DATALOADED,
    stateName: item.stateName,
    dataLoaded: item.dataLoaded,
})

export const addTopicItems = item => ({
    type: ADD_TOPIC_LIST,
    payload: item,
})

export const setOverlay = item => ({
    type: SET_OVERLAY,
    stateName: item.stateName,
    payload: item.action,
})

export const setOverlayData = item => ({
    type: SET_OVERLAY_DATA,
    stateName: item.stateName,
    payload: item.data,
})

export const setActiveIndex = item => ({
    type: SET_ACTIVE_INDEX,
    payload: item.index,
})

export const startLoadmoretopic = item => ({
    type: START_LOADMORE,
    indexValue: item.indexValue,
    payload: item.action,
})

export const setTopicSelected = item => ({
    type: SET_SELECT_TOPIC,
    indexValue: item.index,
    topicId: item.topicId,
})

export const setALLSelected = () => ({
    type: SET_SELECT_ALL,
})

export const setNextTopicItems = item => ({
    type: SET_TOPICDATA_NEXT,
    indexValue: item.indexValue,
    startval: item.startValue,
    endVal: item.endValue,
    offset: item.offset,
    payload: item.data,
})


export const addItem = item => ({
    type: ADD_ITEM,
    stateName: item.stateName,
    blogLoading: item.blogLoading,
    blogLoadmore: item.blogLoadmore,
    blogStart: item.blogStart,
    blogEnd: item.blogEnd,
    blogLoadingname: item.blogLoadingname,
    blogLoadmorename: item.blogLoadmorename,
    blogStartname: item.blogStartname,
    blogEndname: item.blogEndname,
    payload: item.data
})

export const addTopicData = item => ({
    type: ADD_TOPIC,
    stateName: item.stateName,
    topicLoading: item.topicLoading,
    topicLoadingName: item.topicLoadingName,
    payload: item.data
})

export const loadMoreData = item => ({
    type: LOAD_MORE,
    stateName: item.stateName,
    blogLoadingmore: item.blogLoadingmore,
    blogStart: item.blogStart,
    blogEnd: item.blogEnd,
    blogLoadingmorename: item.blogLoadingmorename,
    blogStartname: item.blogStartname,
    blogEndname: item.blogEndname,
    payload: item.data
})

export const loadingMoreData = item => ({
    type: LOADING_MORE,
    blogLoadingmore: item.blogLoadingmore,
    blogLoadingmorename: item.blogLoadingmorename,
})

export const NomoreData = item => ({
    type: NOMORE_DATA,
    blogLoadingmore: item.blogLoadingmore,
    blogLoadingmorename: item.blogLoadingmorename,
    blogLoadmore: item.blogLoadmore,
    blogLoadmorename: item.blogLoadmorename,
})

export const addtbItem = item => ({
    type: ADD_TB_DATA,
    loading: item.loading,
    loadmore: item.loadmore,
    startVal: item.startVal,
    endVal: item.endVal,
    stateName: item.stateName,
    loadingName: item.loadingName,
    loadmoreName: item.loadmoreName,
    startvalName: item.startvalName,
    endvalName: item.endvalName,
    offsetName: item.offsetName,
    offsetVal: item.offsetVal,
    payload: item.data
});

export const addMoretbItem = item => ({
    type: ADD_MORETB_DATA,
    loadmore: item.loadmore,
    loadingmore: item.loadingmore,
    startVal: item.startVal,
    endVal: item.endVal,
    stateName: item.stateName,
    loadmoreName: item.loadmoreName,
    loadingmoreName: item.loadingmoreName,
    startvalName: item.startvalName,
    endvalName: item.endvalName,
    offsetName: item.offsetName,
    offsetVal: item.offsetVal,
    payload: item.data
});

export const setLoadmore = item => ({
    type: SET_LOADMORE,
    loadingmore: item.loadingmore,
    loadingmoreName: item.loadingmoreName,
})

export const endLoadmore = item => ({
    type: END_LOADMORE,
    loadmore: item.loadmore,
    loadingmore: item.loadingmore,
    loadmoreName: item.loadmoreName,
    loadingmoreName: item.loadingmoreName,
    loading: item.loading,
    loadingName: item.loadingName,
})

const initialState = {
    activeIndex: 0,
    isDataLoadedBlog: false,
    isDataLoadedNormal: false,
    isDataLoadedGraph: false,
    isDataLoadedTopic: false,
    blogData1: [],
    blogData2: [],
    blogData3: [],
    blogData4: [],
    blogData5: [],
    blogData6: [],
    blogData7: [],
    blogData8: [],
    blogData9: [],
    blogLoading1: true,
    blogLoading2: true,
    blogLoading3: true,
    blogLoading4: true,
    blogLoading5: true,
    blogLoading6: true,
    blogLoading7: true,
    blogLoading8: true,
    blogLoading9: true,
    blogLoadmore1: false,
    blogLoadmore2: false,
    blogLoadmore3: false,
    blogLoadmore4: false,
    blogLoadmore5: false,
    blogLoadmore6: false,
    blogLoadmore7: false,
    blogLoadmore8: false,
    blogLoadmore9: false,
    blogLoadingmore1: false,
    blogLoadingmore2: false,
    blogLoadingmore3: false,
    blogLoadingmore4: false,
    blogLoadingmore5: false,
    blogLoadingmore6: false,
    blogLoadingmore7: false,
    blogLoadingmore8: false,
    blogLoadingmore9: false,
    blogStart1: 0,
    blogend1: 10,
    blogStart2: 0,
    blogend2: 10,
    blogStart3: 0,
    blogend3: 10,
    blogStart4: 0,
    blogend4: 10,
    blogStart5: 0,
    blogend5: 10,
    blogStart6: 0,
    blogend6: 10,
    blogStart7: 0,
    blogend7: 10,
    blogStart8: 0,
    blogend8: 10,
    blogStart9: 0,
    blogend9: 10,
    normLoading: true,
    normTopicLoading: true,
    normData: [],
    topicData: [],
    normLoadmore: false,
    normLoadingmore: false,
    normStart: 0,
    normEnd: 10,
    normOffset: 0,
    graphLoading: true,
    graphTopicLoading: true,
    graphData: [],
    graphLoadmore: false,
    graphLoadingmore: false,
    graphStart: 0,
    graphEnd: 10,
    graphOffset: 0,
    topicList: [],
    allBlogData: [],
    allBlogOffset: 0,
    allBlogLoadmore: false,
    allBlogListEnd: false,
    allBlogLoading: true,
    allSelected: true,
    isBlogOverlay: false,
    isTextOverlay: false,
    blogOverlay: [],
    textOverlay: [],
}

export default function reducer(state = initialState, action){
    switch(action.type){
        case ADD_ITEM:
            return{
                ...state,
                [action.stateName]: state[action.stateName].concat(action.payload),
                [action.blogLoadingname]: action.blogLoading,
                [action.blogLoadmorename]: action.blogLoadmore,
                [action.blogStartname]: action.blogStart,
                [action.blogEndname]: action.blogEnd,
            }
        case LOAD_MORE:
            return{
                ...state,
                [action.stateName]: state[action.stateName].concat(action.payload),
                [action.blogLoadingmorename]: action.blogLoadingmore,
                [action.blogStartname]: action.blogStart,
                [action.blogEndname]: action.blogEnd,
            }
        case LOADING_MORE:
            return{
                ...state,
                [action.blogLoadingmorename]: action.blogLoadingmore,
            }
        case NOMORE_DATA:
            return{
                ...state,
                [action.blogLoadingmorename]: action.blogLoadingmore,
                [action.blogLoadmorename]: action.blogLoadmore,
            }
        case ADD_TB_DATA:
            return{
                ...state,
                [action.stateName]: state[action.stateName].concat(action.payload),
                [action.loadingName]: action.loading,
                [action.loadmoreName]: action.loadmore,
                [action.startvalName]: action.startVal,
                [action.endvalName]: action.endVal,
                [action.offsetName]: action.offsetVal,
            }
        case ADD_MORETB_DATA:
            return{
                ...state,
                [action.stateName]: state[action.stateName].concat(action.payload),
                [action.loadmoreName]: action.loadmore,
                [action.loadingmoreName]: action.loadingmore,
                [action.startvalName]: action.startVal,
                [action.endvalName]: action.endVal,
                [action.offsetName]: action.offsetVal,
            }
        case SET_LOADMORE:
            return{
                ...state,
                [action.loadingmoreName]: action.loadingmore,
            }
        case END_LOADMORE:
            return{
                ...state,
                [action.loadmoreName]: action.loadmore,
                [action.loadingmoreName]: action.loadingmore,
                [action.loadingName]: action.loading,
            }
        case ADD_TOPIC:
            return{
                ...state,
                [action.stateName]: action.payload,
                [action.topicLoadingName]: action.topicLoading,
            }
        case SET_DATALOADED:
            return{
                ...state,
                [action.stateName]: action.dataLoaded,
            }
        case ADD_TOPIC_LIST:
            return {
                ...state,
                topicList: state.topicList.concat(action.payload),
            }
        case START_LOADMORE:
            let newTopicList = state.topicList;
            newTopicList[action.indexValue].loadmore = action.payload;
            return{
                ...state,
                topicList: newTopicList,
            }
        case SET_TOPICDATA_NEXT:
            let newTopicNextList = state.topicList;
            newTopicNextList[action.indexValue].topicData.push(...action.payload);
            newTopicNextList[action.indexValue].loadmore = false;
            newTopicNextList[action.indexValue].startVal = action.startval;
            newTopicNextList[action.indexValue].endVal = action.endVal;
            newTopicNextList[action.indexValue].dataloading = false;
            newTopicNextList[action.indexValue].offset = action.offset;
            return{
                ...state,
                topicList: newTopicNextList,
            }
        case SET_ACTIVE_INDEX:
            return{
                ...state,
                activeIndex: action.payload,
            }
        case SET_SELECT_TOPIC:
            return{
                ...state,
                topicList: state.topicList.map(blogTB =>{
                    if (blogTB.topicId !== action.topicId) {
                        return {
                            ...blogTB,
                            selected: false,
                        }
                    }
                    return {
                        ...blogTB,
                        selected: true,
                    }
                }),
                allSelected: false,
            }
        case SET_SELECT_ALL:
            return{
                ...state,
                allSelected: true,
                topicList: state.topicList.map(blogTB =>{
                    return {
                        ...blogTB,
                        selected: false,
                    }
                })
            }
        case SET_OVERLAY:
            return{
                ...state,
                [action.stateName]: action.payload,
            }
        case SET_OVERLAY_DATA:
            return{
                ...state,
                [action.stateName]: action.payload,
            }
        default:
            return state
    }
}