import React, { useState, useEffect, useCallback } from 'react';
import Moment from 'moment';
// import { Card, CardContent } from '@react-md/card';
// import { List, ListItem } from '@react-md/list';
// import { Text } from '@react-md/typography';
import { Divider } from '@react-md/divider';
// import Container from './Container';
import { Chip } from '@react-md/chip';
import { Button } from "@react-md/button";
import { useSelector, useDispatch, batch } from "react-redux";

import {Bar} from 'react-chartjs-2';
import logo from './logo.png';

import { setOverlay, setOverlayData } from './reducer';


import {
    ArtTrackSVGIcon,
    ListSVGIcon,
  } from '@react-md/material-icons';


Moment.locale('en');


function GraphDesign({ graphTitle, graphUnit, yAxis, xAxis, xDateTypes, tbID, tbIndex, graphType }){
    const dispatch = useDispatch();
    // const [mType, setMtype] = useState('outlined');
    // const [qType, setQtype] = useState('outlined');
    // const [hType, setHtype] = useState('outlined');
    // const [yType, setYtype] = useState('outlined');
    // const [aType, setAtype] = useState('outlined');
    const [mSelected, setMselected] = useState(false);
    const [qSelected, setQselected] = useState(false);
    const [hSelected, setHselected] = useState(false);
    const [ySelected, setYselected] = useState(false);
    const [aSelected, setAselected] = useState(false);
    const [mDisabled, setMdisabled] = useState(false);
    const [qDisabled, setQdisabled] = useState(false);
    const [hDisabled, setHdisabled] = useState(false);
    const [yDisabled, setYdisabled] = useState(false);
    const [aDisabled, setAdisabled] = useState(false);
    const [mdatax, setMdatax] = useState([]);
    const [qdatax, setQdatax] = useState([]);
    const [hdatax, setHdatax] = useState([]);
    const [ydatax, setYdatax] = useState([]);
    const [mdatay, setMdatay] = useState([]);
    const [qdatay, setQdatay] = useState([]);
    const [hdatay, setHdatay] = useState([]);
    const [ydatay, setYdatay] = useState([]);
    const [xaxisData, setXaxis] = useState([]);
    const [yaxisData, setYaxis] = useState([]);
    // console.log(viewShotRef && viewShotRef.current)
    // const [mZero, setMzero] = useState(noZeroline);
    // const [qZero, setQzero] = useState(noZeroline);
    // const [hZero, setHzero] = useState(noZeroline);
    // const [yZero, setYzero] = useState(noZeroline);
    // const [aZero, setAzero] = useState(noZeroline);

    var mGraphX = [];
    var mGraphY = [];
    var qGraphX = [];
    var qGraphY = [];
    var hGraphX = [];
    var hGraphY = [];
    var yGraphX = [];
    var yGraphY = [];
    var oGraphX = [];
    var oGraphY = [];

    const [blogData, setBlogData] = useState([]);
    const [textData, setTextData] = useState([]);

    const [rankData, setRankData] = useState([]);
    const [rankSize, setRankSize] = useState(0);
    const [rankHeight, setRankHeight] = useState(200);


    useEffect(() => {
        
        if(xDateTypes){
            var looplength = xDateTypes.length;
            for(let i = 0; i < looplength; i++){
                if(xDateTypes[i]){
                    if(xDateTypes[i].includes('month')){
                        mGraphX.push(xAxis[i]);
                        mGraphY.push(yAxis[i]);
                        // if(yAxis[i] < 0){
                        //     setMzero(yZeroline);
                        // }
                    }else if(xDateTypes[i].includes('quarter')){
                        qGraphX.push(xAxis[i]);
                        qGraphY.push(yAxis[i]);
                        // if(yAxis[i] < 0){
                        //     setQzero(yZeroline);
                        // }
                    }else if(xDateTypes[i].includes('half')){
                        hGraphX.push(xAxis[i]);
                        hGraphY.push(yAxis[i]);
                        // if(yAxis[i] < 0){
                        //     setHzero(yZeroline);
                        // }
                    }else if(xDateTypes[i].includes('year')){
                        yGraphX.push(xAxis[i]);
                        yGraphY.push(yAxis[i]);
                        // if(yAxis[i] < 0){
                        //     setYzero(yZeroline);
                        // }
                    }else{
                        oGraphX.push(xAxis[i]);
                        oGraphY.push(yAxis[i]);
                        // if(yAxis[i] < 0){
                        //     setAzero(yZeroline);
                        // }
                    }
                }else{
                    oGraphX.push(xAxis[i]);
                    oGraphY.push(yAxis[i]);
                    // if(yAxis[i] < 0){
                    //     setAzero(yZeroline);
                    // }
                }
            }
        }
        
    
        if(mGraphX.length === 0){
            setMdisabled(true);
            setMselected(false);
        }
        if(qGraphX.length === 0){
            setQdisabled(true);
            setQselected(false);
        }
        if(hGraphX.length === 0){
            setHdisabled(true);
            setHselected(false);
        }
        if(yGraphX.length === 0){
            setYdisabled(true);
            setYselected(false);
        }
        if(oGraphX.length === 0){
            setAdisabled(true);
            setAselected(false);
        }
    
        if(mGraphX.length > 0){
            setMselected(true);
        }else if(qGraphX.length > 0){
            setQselected(true);
        }else if(hGraphX.length > 0){
            setHselected(true);
        }else if(yGraphX.length > 0){
            setYselected(true);
        }else if(oGraphX.length > 0){
            setAselected(true);
        }

        if(graphType == "Ranking"){
            var alen = yAxis.length;
            var RankingY = [];
            setRankSize(alen);
            setRankHeight(200/alen);
            for(var i = 0; i < alen; i++){
                RankingY.push({
                  rank: yAxis[i],
                  name: xAxis[i]
                });
            }
            setRankData(RankingY);
        }

        setMdatax(mGraphX);
        setMdatay(mGraphY);
        setQdatax(qGraphX);
        setQdatay(qGraphY);
        setHdatax(hGraphX);
        setHdatay(hGraphY);
        setYdatax(yGraphX);
        setYdatay(yGraphY);

        // setAselected(true);
        setXaxis(oGraphX);
        setYaxis(oGraphY);
            // console.log(JSON.stringify(mGraphX));
        // updateSelected(setAselected, setAtype, xAxis, yAxis);
        return () => {
            
        };
      }, []);


    function updateSelected(setSelected){
        // console.log("update");
        // setMtype('outlined');
        // setQtype('outlined');
        // setHtype('outlined');
        // setYtype('outlined');
        // setAtype('outlined');
        // setType('flat');

        setMselected(false);
        setQselected(false);
        setHselected(false);
        setYselected(false);
        setAselected(false);
        setSelected(true);
        // setXaxis(xData);
        // setYaxis(yData);
    }

    const displayOverlayBlog = useCallback(
        () => {
          //   console.log(JSON.stringify(topicList))
          if(blogData.length === 0){
            batch(() => {
                dispatch(setOverlay({
                    stateName: 'isBlogOverlay',
                    action: true,
                }));
                dispatch(setOverlayData({
                    data: [],
                    stateName: 'blogOverlay'
                  }))
              });
            (async function load() {
                const response = await fetch('https://api.numerokno.com/api/graphBlogs/', {
                  method: 'POST',
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    "app-key": "E9FE46D9-FC53-480F-9DC6-D26A7DE233A0"
                  },
                  body: JSON.stringify({
                      userId: 1,
                      offset: 0,
                      graphId: tbID
                  })
                });
                const result = await response.json();
              //   console.log(JSON.stringify(result))
                if(result.titbits.length === 0){
                //   dispatch(startLoadmoretopic({
                //     indexValue: index,
                //     action: false,
                //   }))
                }else{
                  dispatch(setOverlayData({
                    data: result.titbits,
                    stateName: 'blogOverlay'
                  }))
                  setBlogData(result.titbits);
                }
              })();
          }else{
            batch(() => {
                dispatch(setOverlay({
                    stateName: 'isBlogOverlay',
                    action: true,
                }));
                dispatch(setOverlayData({
                    data: blogData,
                    stateName: 'blogOverlay'
                  }))
              });
          }
        },[dispatch, tbID, blogData],
    )

    const displayOverlayText = useCallback(
        () => {
          //   console.log(JSON.stringify(topicList))
          
          if(textData.length === 0){
            batch(() => {
                dispatch(setOverlay({
                    stateName: 'isTextOverlay',
                    action: true,
                }));
                dispatch(setOverlayData({
                    data: [],
                    stateName: 'textOverlay'
                  }))
              });
            (async function load() {
                const response = await fetch('https://api.numerokno.com/api/graphtitbits/', {
                  method: 'POST',
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    "app-key": "E9FE46D9-FC53-480F-9DC6-D26A7DE233A0"
                  },
                  body: JSON.stringify({
                      userId: 1,
                      offset: 0,
                      graphId: tbID
                  })
                });
                const result = await response.json();
              //   console.log(JSON.stringify(result))
                if(result.titbits.length === 0){
                //   dispatch(startLoadmoretopic({
                //     indexValue: index,
                //     action: false,
                //   }))
                }else{
                  dispatch(setOverlayData({
                    data: result.titbits,
                    stateName: 'textOverlay'
                  }))
                  setTextData(result.titbits)
                }
              })();
          }else{
            batch(() => {
                dispatch(setOverlay({
                    stateName: 'isTextOverlay',
                    action: true,
                }));
                dispatch(setOverlayData({
                    data: textData,
                    stateName: 'textOverlay'
                  }))
              });
          }
        },[dispatch, textData, tbID],
    )
    
    

    return(
        <div style={{width: '96%', height: 'auto', display: 'flex', flexDirection: 'column',boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)', borderRadius: 5,margin: '2%', whiteSpace: 'pre-wrap'}}>
            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', padding: 5, justifyContent: 'flex-start',}}>
                <Chip style={{marginRight: 2, marginBottom: 2}} selectedThemed selected={mSelected} disabled={mDisabled} onClick={() => updateSelected(setMselected)}>Month</Chip>

                <Chip style={{marginRight: 2, marginBottom: 2}} selectedThemed selected={qSelected} disabled={qDisabled} onClick={() => updateSelected(setQselected)}>Quarter</Chip>

                <Chip style={{marginRight: 2, marginBottom: 2}} selectedThemed selected={hSelected} disabled={hDisabled} onClick={() => updateSelected(setHselected)}>Half Year</Chip>

                <Chip style={{marginRight: 2, marginBottom: 2}} selectedThemed selected={ySelected} disabled={yDisabled} onClick={() => updateSelected(setYselected)}>Year</Chip>

                <Chip style={{marginRight: 2, marginBottom: 2}} selectedThemed selected={aSelected} disabled={aDisabled} onClick={() => updateSelected(setAselected)}>Others</Chip>
            </div>

            {graphType != 'Ranking' ? <div><p style={{fontSize: 14, 'textAlign': "center"}}>{graphTitle} ({graphUnit})</p>
            <div style={{width: '100%', height: 310,}}>
                { mSelected ? <Bar 
                    data={{
                        labels: mdatax,
                        datasets: [
                        {
                            label: graphTitle,
                            backgroundColor: 'rgba(0,60,100,1)',
                            borderColor: 'rgba(0,60,100,1)',
                            borderWidth: 1,
                            hoverBackgroundColor: 'rgba(0,60,100,1)',
                            hoverBorderColor: 'rgba(0,60,100,1)',
                            data: mdatay,
                        }
                        ]
                    }}
                    width={400} 
                    height={300} 
                    options={{
                        legend: {
                            display: false,
                            position: 'top',
                            labels: {
                                boxWidth: 0,
                                fontColor: 'black'
                            },
                            onClick: (e) => e.stopPropagation()
                        },
                        animation: {
                            duration: 0 // general animation time
                        },
                        hover: {
                            animationDuration: 0 // duration of animations when hovering an item
                        },
                        responsiveAnimationDuration: 0,
                        tooltips: {
                            callbacks: {
                                label: function(tooltipItems, data) {
                                    return tooltipItems.yLabel;
                                }
                            }

                        },
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true
                                }
                            }]
                        },
                        maintainAspectRatio: false,
                        responsive:true,
                    }}
                /> : null }

                { qSelected ? <Bar 
                    data={{
                        labels: qdatax,
                        datasets: [
                        {
                            label: graphTitle,
                            backgroundColor: 'rgba(0,60,100,1)',
                            borderColor: 'rgba(0,60,100,1)',
                            borderWidth: 1,
                            hoverBackgroundColor: 'rgba(0,60,100,1)',
                            hoverBorderColor: 'rgba(0,60,100,1)',
                            data: qdatay,
                        }
                        ]
                    }}
                    width={400} 
                    height={300} 
                    options={{
                        legend: {
                            display: false,
                            position: 'top',
                            labels: {
                                boxWidth: 0,
                                fontColor: 'black'
                            },
                            onClick: (e) => e.stopPropagation()
                        },
                        animation: {
                            duration: 0 // general animation time
                        },
                        hover: {
                            animationDuration: 0 // duration of animations when hovering an item
                        },
                        responsiveAnimationDuration: 0,
                        tooltips: {
                            callbacks: {
                                label: function(tooltipItems, data) {
                                    return tooltipItems.yLabel;
                                }
                            }

                        },
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true
                                }
                            }]
                        },
                        maintainAspectRatio: false,
                        responsive:true,
                    }}
                /> : null }

                { hSelected ? <Bar 
                    data={{
                        labels: hdatax,
                        datasets: [
                        {
                            label: graphTitle,
                            backgroundColor: 'rgba(0,60,100,1)',
                            borderColor: 'rgba(0,60,100,1)',
                            borderWidth: 1,
                            hoverBackgroundColor: 'rgba(0,60,100,1)',
                            hoverBorderColor: 'rgba(0,60,100,1)',
                            data: hdatay,
                        }
                        ]
                    }}
                    width={400} 
                    height={300} 
                    options={{
                        legend: {
                            display: false,
                            position: 'top',
                            labels: {
                                boxWidth: 0,
                                fontColor: 'black'
                            },
                            onClick: (e) => e.stopPropagation()
                        },
                        animation: {
                            duration: 0 // general animation time
                        },
                        hover: {
                            animationDuration: 0 // duration of animations when hovering an item
                        },
                        responsiveAnimationDuration: 0,
                        tooltips: {
                            callbacks: {
                                label: function(tooltipItems, data) {
                                    return tooltipItems.yLabel;
                                }
                            }

                        },
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true
                                }
                            }]
                        },
                        maintainAspectRatio: false,
                        responsive:true,
                    }}
                /> : null }

                { ySelected ? <Bar 
                    data={{
                        labels: ydatax,
                        datasets: [
                        {
                            label: graphTitle,
                            backgroundColor: 'rgba(0,60,100,1)',
                            borderColor: 'rgba(0,60,100,1)',
                            borderWidth: 1,
                            hoverBackgroundColor: 'rgba(0,60,100,1)',
                            hoverBorderColor: 'rgba(0,60,100,1)',
                            data: ydatay,
                        }
                        ]
                    }}
                    width={400} 
                    height={300} 
                    options={{
                        legend: {
                            display: false,
                            position: 'top',
                            labels: {
                                boxWidth: 0,
                                fontColor: 'black'
                            },
                            onClick: (e) => e.stopPropagation()
                        },
                        animation: {
                            duration: 0 // general animation time
                        },
                        hover: {
                            animationDuration: 0 // duration of animations when hovering an item
                        },
                        responsiveAnimationDuration: 0,
                        tooltips: {
                            callbacks: {
                                label: function(tooltipItems, data) {
                                    return tooltipItems.yLabel;
                                }
                            }

                        },
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true
                                }
                            }]
                        },
                        maintainAspectRatio: false,
                        responsive:true,
                    }}
                /> : null }

                { aSelected ? <Bar 
                    data={{
                        labels: xaxisData,
                        datasets: [
                        {
                            label: graphTitle,
                            backgroundColor: 'rgba(0,60,100,1)',
                            borderColor: 'rgba(0,60,100,1)',
                            borderWidth: 1,
                            hoverBackgroundColor: 'rgba(0,60,100,1)',
                            hoverBorderColor: 'rgba(0,60,100,1)',
                            data: yaxisData,
                        }
                        ]
                    }}
                    width={400} 
                    height={300} 
                    options={{
                        legend: {
                            display: false,
                            position: 'top',
                            labels: {
                                boxWidth: 0,
                                fontColor: 'black'
                            },
                            onClick: (e) => e.stopPropagation()
                        },
                        animation: {
                            duration: 0 // general animation time
                        },
                        hover: {
                            animationDuration: 0 // duration of animations when hovering an item
                        },
                        responsiveAnimationDuration: 0,
                        tooltips: {
                            callbacks: {
                                label: function(tooltipItems, data) {
                                    return tooltipItems.yLabel;
                                }
                            }

                        },
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true
                                }
                            }]
                        },
                        maintainAspectRatio: false,
                        responsive:true,
                    }}
                /> : null }
                
            </div></div> : <div><p style={{fontSize: 14, 'textAlign': "center"}}>{graphTitle}</p><div style={{width: '100%', height: 310,}}>
                <div style={{display: 'flex', flexDirection: "row", alignItems: 'flex-end', overflowX: 'auto', padding: 5,}}>
                {rankData.map((item, i) => {return(
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 5, justifyContent: 'flex-end'}}>
                      <span style={{fontSize: 15}}>{item.rank}</span>
                      <div style={{width: 50,backgroundColor: 'rgba(0,60,100,1)', height: rankHeight*(rankSize - i),}}></div>
                      <span style={{fontSize: 14}}>{item.name}</span>
                    </div>
                  )})}
                </div>
                </div></div>}

            
            <Divider />
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', padding: 5, textAlign: "right"}}>
                <Button
                    buttonType="icon"
                    theme="warning"
                    aria-label="Add"
                    onClick={() => displayOverlayBlog()}
                >
                    <ArtTrackSVGIcon />
                </Button>
                <Button
                    buttonType="icon"
                    theme="primary"
                    aria-label="Add"
                    onClick={() => displayOverlayText()}
                >
                    <ListSVGIcon />
                </Button>
                <img src={logo} alt="TitBits" style={{height: 30,width: 30, resizeMode: "stretch",}} />
            </div>
    </div>
    )
};

export default GraphDesign
