import React, { useEffect, useState, useCallback } from 'react';
// import logo from './logo.svg';
// import { Layout, useLayoutNavigation } from 'react-md';
import './App.css';
import './App.scss';
import { AppBar, AppBarNav, AppBarTitle, AppBarAction, } from '@react-md/app-bar';
import {
  ArrowBackSVGIcon,
  ArrowUpwardSVGIcon
} from '@react-md/material-icons';

import { AppSizeListener, useAppSize, useToggle } from '@react-md/utils';
import { List, ListItem } from '@react-md/list';
import { CircularProgress } from '@react-md/progress';
import CustomRow from './TrendingDesign';
import Container from './Container';
import { CachedSVGIcon } from '@react-md/material-icons';
import { TextIconSpacing } from '@react-md/icon';
import { Button } from '@react-md/button';

import { withRouter } from "react-router-dom";

import logo from './logo.png';


// const navItems = {};

 function BlogTag(props) {
  const context = useAppSize();

  const getLayoutWidth = (lytdata) => {
    if(lytdata.isPhone){
      return '100%';
    }
    if(lytdata.isTablet){
      return '50%';
    }
    if(lytdata.isDesktop){
      return '40%';
    }
    if(lytdata.isLargeDesktop){
      return '30%';
    }
  }

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [loadmore, setLoadmore] = useState(false);

  const [loadingmore, setLoadingmore] = useState(false);

  const [textData, setTextdata] = useState('');
  const [errorText, setErrortext] = useState('');

//   const [start_val, incStart] = useState(0);
//   const [end_val, incEnd] = useState(10);

const [visible, setVisible] = useState(false);

  const toggleVisible = () => { 
    const scrolled = document.documentElement.scrollTop; 
    if (scrolled > 0){ 
      setVisible(true) 
    }  
    else if (scrolled <= 0){ 
      setVisible(false) 
    } 
  };

  const scrollToTop = () =>{ 
    window.scrollTo({ 
      top: 0, 
      behavior: 'auto'
      /* you can also use 'auto' behaviour 
         in place of 'smooth' */
    }); 
  }; 

  window.addEventListener('scroll', toggleVisible);

  const loadMoreSearch = () => {
    setLoadingmore(true);
    loadFunc();
  }
  
  const loadFunc = useCallback(
    () => {
        // setLoadingmore(true);
  
        const { tagData } = props.location;
      var searchWord = tagData;
      if (searchWord.indexOf("#") != -1) {
          searchWord = searchWord.replace("#", "");
      }
        
        fetch('https://api.numerokno.com/api/search_blog_tags/', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            "app-key": "E9FE46D9-FC53-480F-9DC6-D26A7DE233A0"
          },
          body: JSON.stringify({
            userId: 1,
            keyword: searchWord,
            offset: offset,
          })
        }).then((response) => response.json())
          .then((json) => {
            if(json.titbits.length === 0){
              setLoadmore(false);
            }else{
              setData(prevState => ([...prevState, ...json.titbits]));
              // setLoading(false);
              setLoadmore(true);
              setOffset(json.offset);
            }
            
            // console.log(JSON.stringify(this.state.tbData));
          })
          .catch((error) => setErrortext('Oops! error has occurred'))
          .finally(() => {
            // setLoading(false);
            setLoadingmore(false);
          });
    },
    [offset],
  );



  useEffect(() => {
    let cancelled = false;
    // console.log(props);
    // setState({ loading: true, data: [], loadmore: false, });
    setLoading(true);
    const { tagData } = props.location;
    var searchWord = tagData;
    if (searchWord.indexOf("#") != -1) {
        searchWord = searchWord.replace("#", "");
    }
    
    (async function load() {
      const response = await fetch('https://api.numerokno.com/api/search_blog_tags/', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          "app-key": "E9FE46D9-FC53-480F-9DC6-D26A7DE233A0"
        },
        body: JSON.stringify({
          userId: 1,
          keyword: searchWord,
          offset: 0,
        })
      });
      
      const result = await response.json();
      // const data = result.titbits_ls;
      // console.log(JSON.stringify(result));

      if (!cancelled) {
        setLoading(false);
        if(result.titbits.length === 0){
          setLoadmore(false);
        }else{
          setData(result.titbits);
          setLoading(false);
          setLoadmore(true);
          setOffset(result.offset);
        }
        // setLoadmore(true);
        // incStart(10);
        // incEnd(20);
      }
    })();

    return () => {
      cancelled = true;
    };
  }, []);

   return (
     <div>
      <AppBar theme="clear" height="none">
        <AppBarNav id="TagBack" aria-label="Back" onClick={props.history.goBack}>
          <ArrowBackSVGIcon />
        </AppBarNav>
        <AppBarTitle
          style={{display: 'flex', alignItems: 'center',}}
        >
          {props.location.tagData}
        </AppBarTitle>
      </AppBar>
      <div>
        {/* <InfiniteScroll
                  pageStart={0}
                  loadMore={loadFunc}
                  hasMore={true}
                  loader={<div className="loader" key={0}>Loading ...</div>}
                  useWindow={false}
              > */}
          <List style={{display: 'flex', flex: 1,flexDirection: 'column'}}>
              
              {loading ? <CircularProgress id="loading-tab-2" /> : data.map(({ titbit_id, created_by, date, tags_list, input, source_url, src, color, source_name, topic_data, site_disc }) => (
                <ListItem key={titbit_id} id={`simple-item-${titbit_id}`} style={{padding: 1,}}>
                  <CustomRow
                    title={created_by}
                    // description={item.title}
                    // image_url={item.profile_pic}
                    tbDate={date}
                    // editedBy={item.admin_edit}
                    tagData={tags_list}
                    // tbImage={item.tibits_img}
                    searchData={false}
                    tbID={titbit_id}
                    // tbIndex={index}
                    orgData={input}
                    sourceUrl={source_url}
                    srcVal={src}
                    topicData={topic_data}
                    bcgColor={color}
                    sourceName={source_name}
                    site_disc={site_disc}
                  />
                </ListItem>
              ))
              }
              {/* {loadmore ? <Button onClick={loadFunc} theme={loadingmore ? 'disabled' : 'primary'} themeType="outline"><TextIconSpacing icon={ loadingmore ? (<CircularProgress id={'loadmore-id'} centered={false} />) : (<CachedSVGIcon />)}>Load More</TextIconSpacing></Button> : null} */}

              {loadmore ? <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', margin: 'auto', paddingRight: 5,}}><Button id="icon-button-9" buttonType="icon" theme={loadingmore ? 'disabled' : 'primary'} themeType="contained" aria-label="Load More" onClick={loadMoreSearch} > {loadingmore ? <CircularProgress id={'loadmore-Trending'} centered={false} /> : <CachedSVGIcon />}  </Button></div> : null}

              { visible ? <Button floating="bottom-left" theme="clear" onClick={scrollToTop}><ArrowUpwardSVGIcon /></Button> : null }
          </List>
        {/* </InfiniteScroll> */}
      </div>
    </div>
   );
 }

 export default withRouter(BlogTag);
