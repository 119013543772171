import React, { useEffect, useState, useCallback } from 'react';
// import { MediaContainer, MediaOverlay } from '@react-md/media';
import { CircularProgress } from '@react-md/progress';
import { TabPanel } from '@react-md/tabs';
// import { Text } from '@react-md/typography';
// import { GridList, GridListCell } from '@react-md/utils';
import { List, ListItem } from '@react-md/list';
import CustomRow from './TbDesign';
import Container from './Container';
// import InfiniteScroll from 'react-infinite-scroller';
// import { useTimeout, useToggle } from '@react-md/utils';
import { CachedSVGIcon, CallReceivedFontIcon, SearchSVGIcon } from '@react-md/material-icons';
// import { TextIconSpacing } from '@react-md/icon';
import { Button } from '@react-md/button';
import { Chip } from '@react-md/chip';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addtbItem, addMoretbItem, setLoadmore, endLoadmore, addTopicData, setDataLoaded } from './reducer';

// import { randomInt } from './random';

// var startVal = 0;
// var endVal = 10;

const NormalTB = (props) => {

  const dispatch = useDispatch();
  const normLoading = useSelector(state => state.normLoading);
  const normData = useSelector(state => state.normData);
  const normLoadmore = useSelector(state => state.normLoadmore);
  const normLoadingmore = useSelector(state => state.normLoadingmore);
  const normStart = useSelector(state => state.normStart);
  const normEnd = useSelector(state => state.normEnd);
  const topicData = useSelector(state => state.topicData);
  const topicLoading = useSelector(state => state.normTopicLoading);
  const isDataLoadedTB = useSelector(state => state.isDataLoadedNormal);
  const isDataLoadedTopic = useSelector(state => state.isDataLoadedTopic);
  const normOffset = useSelector(state => state.normOffset);

  // const [loading, setLoading] = useState(false);
  // const [loadingtopic, setLoadingtopic] = useState(true);
  // const [data, setData] = useState([]);
  // const [datatopic, setDatatopic] = useState([]);
  // const [loadmore, setLoadmore] = useState(false);

  // const [loadingmore, setLoadingmore] = useState(false);

  // const [start_val, incStart] = useState(0);
  // const [end_val, incEnd] = useState(10);

  let history = useHistory();

  const loadFunc = useCallback(
    () => {
        // console.log("end reached");
        // setLoadingmore(true);
        dispatch(setLoadmore({
          loadingmore: true,
          loadingmoreName: 'normLoadingmore',
        }));
        
        // startVal = endVal;
        // endVal = endVal + 10;
        (async function load() {
          const response = await fetch('https://api.numerokno.com/api/all_titbits/', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              "app-key": "E9FE46D9-FC53-480F-9DC6-D26A7DE233A0"
            },
            body: JSON.stringify({
              userId: 1,
              start_value: normStart,
              end_value: normEnd,
              offset: normOffset,
            })
          });
          const result = await response.json();
          
            // setData(prevState => ([...prevState, ...result]));
            // setLoadingmore(false);
            // incStart(end_val);
            // incEnd(end_val + 10);
            if(result.titbits.length === 0){
              // setLoadmore(false)
              // setLoadingMore(false);
              dispatch(endLoadmore({
                loadingmore: false,
                loadingmoreName: 'normLoadingmore',
                loadmore: false,
                loadmoreName: 'normLoadmore',
                loading: false,
                loadingName: 'normLoading',
              }));
            }else{
              // setData(prevState => ([...prevState, ...result]));
              // setLoadingMore(false);
              // incStart(end_val);
              // incEnd(end_val + 10);
              dispatch(addMoretbItem({
                stateName: 'normData',
                data: result.titbits,
                loadingmore: false,
                loadmore: true,
                startVal: normEnd,
                endVal: (normEnd + 10),
                loadingmoreName: 'normLoadingmore',
                loadmoreName: 'normLoadmore',
                startvalName: 'normStart',
                endvalName: 'normEnd',
                offsetName: 'normOffset',
                offsetVal: result.offset,
              }));
            }

        })();
    },
    [normStart, normEnd],
  );

  useEffect(() => {
    let cancelled = false;
    // console.log('request')
    // setState({ loading: true, data: [], loadmore: false, });
    // setLoading(true);

    if(!isDataLoadedTB){
      (async function load() {
        const response = await fetch('https://api.numerokno.com/api/all_titbits/', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            "app-key": "E9FE46D9-FC53-480F-9DC6-D26A7DE233A0"
          },
          body: JSON.stringify({
            userId: 1,
            start_value: normStart,
            end_value: normEnd,
            offset: normOffset,
          })
        });
        const result = await response.json();
        // const data = result;
      //   const start = randomInt({ min: 0, max: 20 });
      //   const total = randomInt({ min: 6, max: 14 });
      //   const data = list.slice(start, start + total).map(({ author, id }) => ({
      //     name: author,
      //     url: `https://picsum.photos/250?image=${id}`,
      //   }));
  
        if (!cancelled) {
          // setState({
          //   data,
          //   loading: false,
          //   loadmore: true,
          // });
          // setData(data);
          // setLoading(false);
          // setLoadmore(true);
          // incStart(10);
          // incEnd(20);
  
          if(result.titbits.length === 0){
            // setLoadmore(false)
            // setLoadingMore(false);
            dispatch(endLoadmore({
              loadingmore: false,
              loadingmoreName: 'normLoadingmore',
              loadmore: false,
              loadmoreName: 'normLoadmore',
              loading: false,
              loadingName: 'normLoading',
            }));
          }else{
            // setData(prevState => ([...prevState, ...result]));
            // setLoadingMore(false);
            // incStart(end_val);
            // incEnd(end_val + 10);
            dispatch(addtbItem({
              stateName: 'normData',
              data: result.titbits,
              loading: false,
              loadmore: true,
              startVal: normEnd,
              endVal: (normEnd + 10),
              loadingName: 'normLoading',
              loadmoreName: 'normLoadmore',
              startvalName: 'normStart',
              endvalName: 'normEnd',
              offsetName: 'normOffset',
              offsetVal: result.offset,
            }));
          }
  
        }
      })();
    }
    
    return () => {
      cancelled = true;
      dispatch(setDataLoaded({
        stateName: 'isDataLoadedNormal',
        dataLoaded: true,
      }))
    };
  }, []);

  // useEffect(() => {
  //   let cancelled = false;
  //   // console.log('request')
  //   // setState({ loading: true, data: [], loadmore: false, });
  //   // setLoading(true);
  //   if(!isDataLoadedTopic){
  //     (async function load() {
  //       const response = await fetch('https://api.numerokno.com/api/topic_manage/', {
  //         method: 'POST',
  //         headers: {
  //           Accept: 'application/json',
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify({
  //           func: 4
  //         })
  //       });
  //       const result = await response.json();
  //       const data = result;
  
  //       if (!cancelled) {
  //         // setDatatopic(data);
  //         // setLoadingtopic(false);
  //         dispatch(addTopicData({
  //           stateName: 'topicData',
  //           data: result,
  //           topicLoading: false,
  //           topicLoadingName: 'normTopicLoading',
  //         }));
  //       }
  //     })();
  //   }
    

  //   return () => {
  //     cancelled = true;
  //     dispatch(setDataLoaded({
  //       stateName: 'isDataLoadedTopic',
  //       dataLoaded: true,
  //     }))
  //   };
  // }, []);

  return (
    <TabPanel {...props}>
      <Container centered>
        {/* <InfiniteScroll
                  pageStart={0}
                  loadMore={loadFunc}
                  hasMore={true}
                  loader={<div className="loader" key={0}>Loading ...</div>}
                  useWindow={false}
              > */}
        <div style={{width: '100%', height: 50,}}>
          <List style={{display: 'flex', flexDirection: 'row', flex: 1,justifyContent: 'flex-start',overflowY: 'auto',}}>
            <Chip style={{marginLeft: 5,}} key={'searchbtn'} onClick={() => history.push({pathname: '/searchTB',})} leftIcon={<SearchSVGIcon />}>Search</Chip>
            {/* {topicLoading ? null : topicData.map(({Topic_name}, index) => (
              <Chip style={{marginLeft: 5,}} key={`${Topic_name}-${index}`}>{Topic_name}</Chip>))} */}
          </List>
        </div>
        <div style={{width: '100%',}}>
          <List style={{display: 'flex', flexDirection: 'column', flex: 1,}}>
              
              {normLoading ? <CircularProgress id="loading-tab-2" /> : normData.map(({ profile_pic, titbit_id, created_by, title, numbers, date, admin_edit, tags_list, tibits_img, input, source_url, src, color, source_name, graphTitle, years }) => (
                <ListItem key={titbit_id} id={`simple-item-${titbit_id}`} style={{padding: 1,}}>
                  <CustomRow
                      title={created_by}
                      description={title}
                      image_url={profile_pic}
                      // favorite={favourite}
                      numbersData={numbers}
                      tbDate={date}
                      editedBy={admin_edit}
                      tagData={tags_list}
                      // navigate={this.props.navigation.navigate}
                      tbImage={tibits_img}
                      searchData={true}
                      tbID={titbit_id}
                      // tbIndex={index}
                      // userID={this.props.loginDetails.id}
                      orgData={input}
                      sourceUrl={source_url}
                      srcVal={src}
                      bcgColor={color}
                      sourceName={source_name}
                      graphTitle={graphTitle}
                      yearsData={years}
                  />
                </ListItem>
              ))
              }
              {/* {normLoadmore ? <Button onClick={loadFunc} theme={normLoadingmore ? 'disabled' : 'primary'} themeType="outline"><TextIconSpacing icon={ normLoadingmore ? (<CircularProgress id={'loadmore-id'} centered={false} />) : (<CachedSVGIcon />)}>Load More</TextIconSpacing></Button> : null} */}
              {normLoadmore ? <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', margin: 'auto', paddingRight: 5,}}><Button id="icon-button-9" buttonType="icon" theme={normLoadingmore ? 'disabled' : 'primary'} themeType="contained" aria-label="Load More" onClick={loadFunc} > {normLoadingmore ? <CircularProgress id={'loadmore-Trending'} centered={false} /> : <CachedSVGIcon />}  </Button></div> : null}
          </List>
        </div>
          
        {/* </InfiniteScroll> */}
      </Container>
    </TabPanel>
  );
};

export default NormalTB;