import React, { useState, useCallback } from 'react';
// import { MediaContainer, MediaOverlay } from '@react-md/media';
import { CircularProgress } from '@react-md/progress';
// import { TabPanel } from '@react-md/tabs';
// import { Text } from '@react-md/typography';
// import { GridList, GridListCell } from '@react-md/utils';
import { List, ListItem } from '@react-md/list';
import CustomRow from './TbDesign';
import Container from './Container';
// import InfiniteScroll from 'react-infinite-scroller';
// import { useTimeout, useToggle } from '@react-md/utils';
import { CachedSVGIcon, CallReceivedFontIcon, SearchSVGIcon } from '@react-md/material-icons';
import { TextIconSpacing } from '@react-md/icon';
import { Button } from '@react-md/button';
// import { Chip } from '@react-md/chip';
// import { useHistory } from "react-router-dom";
// import {
//   Form,
//   TextField,
// } from '@react-md/form';
import { FontIcon } from '@react-md/icon';
import { AppBar, AppBarNav, AppBarTitle } from '@react-md/app-bar';
import {
  ArrowBackSVGIcon,
  ArrowUpwardSVGIcon
} from '@react-md/material-icons';

import { withRouter } from "react-router-dom";

import { useAppSize } from '@react-md/utils';
import logo from './logo.png';
// import { Controller, useForm } from 'react-hook-form';

// import { randomInt } from './random';

// var startVal = 0;
// var endVal = 10;

const SearchTB = (props) => {

  const context = useAppSize();

  const getLayoutWidth = (lytdata) => {
    if(lytdata.isPhone){
      return '100%';
    }
    if(lytdata.isTablet){
      return '50%';
    }
    if(lytdata.isDesktop){
      return '40%';
    }
    if(lytdata.isLargeDesktop){
      return '30%';
    }
  }

  // const {
  //   control,
  //   reset,
  //   handleSubmit,
  //   errors: { serachData },
  // } = useForm({ mode: 'onChange' });

  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  // const [loadingtopic, setLoadingtopic] = useState(true);
  const [data, setData] = useState([]);
  // const [datatopic, setDatatopic] = useState([]);
  const [loadmore, setLoadmore] = useState(false);

  const [loadingmore, setLoadingmore] = useState(false);
  const [offset, setOffset] = useState(0);

  // const [start_val, incStart] = useState(0);
  // const [end_val, incEnd] = useState(10);
  const [textData, setTextdata] = useState('');
  const [errorText, setErrortext] = useState('');

  const [visible, setVisible] = useState(false);

  const toggleVisible = () => { 
    const scrolled = document.documentElement.scrollTop; 
    if (scrolled > 0){ 
      setVisible(true) 
    }  
    else if (scrolled <= 0){ 
      setVisible(false) 
    } 
  };

  const scrollToTop = () =>{ 
    window.scrollTo({ 
      top: 0, 
      behavior: 'auto'
      /* you can also use 'auto' behaviour 
         in place of 'smooth' */
    }); 
  }; 

  window.addEventListener('scroll', toggleVisible);

//   let history = useHistory();

  // const searchTextTB = useCallback(
  //   () => {
  //     alert("fff", textData);
  //   }, [textData],
  // )

  const handleSubmit = () => {
    // alert(`Submitting Name ${textData}`);
    if(textData.length === 0){
      setErrortext('Enter search text');
    }else if(textData.length <= 1){
      setErrortext('Search text should contain minimum 2 letters');
    }else{
      setErrortext('');
      setLoading(true);
      setData([]);
      setSearching(false);
      loadFunc(textData);
      
    }
  }

  const loadMoreSearch = () => {
    setLoadingmore(true);
    loadFunc(textData);
  }

  const loadFunc = useCallback(
    (searchText) => {
        // setLoadingmore(true);
        
        fetch('https://api.numerokno.com/api/search_items/', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            "app-key": "E9FE46D9-FC53-480F-9DC6-D26A7DE233A0"
          },
          body: JSON.stringify({
            userId: 1,
            keyword: searchText,
            offset: offset,
          })
        }).then((response) => response.json())
          .then((json) => {
            console.log(JSON.stringify(json));
            console.log(json.offset);
            if(json.titbits.length === 0){
              setErrortext('No TitBit found');
              setLoadmore(false);
            }else{
              setData(prevState => ([...prevState, ...json.titbits]));
              setLoading(false);
              setSearching(true);
              setLoadmore(true);
              setOffset(json.offset);
            }
            
            // console.log(JSON.stringify(this.state.tbData));
          })
          .catch((error) => setErrortext('Oops! error has occurred'))
          .finally(() => {
            setLoading(false);
            setLoadingmore(false);
          });
        // (async function load() {
        //   try{
        //     const response = await fetch('https://titbitss.pythonanywhere.com/api/search_items/', {
        //       method: 'POST',
        //       headers: {
        //         Accept: 'application/json',
        //         'Content-Type': 'application/json'
        //       },
        //       body: JSON.stringify({
        //         user_id: 11,
        //         keyword: searchText,
        //       })
        //     });
        //     const result = await response.json();
        //     if(result.length > 0){
        //       setData(prevState => ([...prevState, ...result.titbits_ls]));
        //       setLoading(false);
        //       setSearching(true);
        //       // setLoadingmore(false);
        //       // incStart(end_val);
        //       // incEnd(end_val + 10);
        //     }else{
        //       setErrortext('No TitBit found');
        //     }
        //   }catch{
        //     setErrortext('Oops! error has occurred');
        //     // setErrortext(searchText);
        //   }finally{
        //     setLoading(false);
        //     setSearching(true);
        //   }
            

        // })();
    },
    [offset],
  );

//   useEffect(() => {
//     let cancelled = false;
//     setLoading(true);
    
//     (async function load() {
//       const response = await fetch('https://titbitss.pythonanywhere.com/api/all_titbits/', {
//         method: 'POST',
//         headers: {
//           Accept: 'application/json',
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({
//           user_id: 11,
//           start_value: 0,
//           end_value: 10
//         })
//       });
//       const result = await response.json();
//       const data = result;

//       if (!cancelled) {
//         setData(data);
//         setLoading(false);
//         setLoadmore(true);
//         incStart(10);
//         incEnd(20);
//       }
//     })();

//     return () => {
//       cancelled = true;
//     };
//   }, []);

//   useEffect(() => {
//     let cancelled = false;
    
//     (async function load() {
//       const response = await fetch('https://titbitss.pythonanywhere.com/api/topic_manage/', {
//         method: 'POST',
//         headers: {
//           Accept: 'application/json',
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({
//           func: 4
//         })
//       });
//       const result = await response.json();
//       const data = result;

//       if (!cancelled) {
//         setDatatopic(data);
//         setLoadingtopic(false);
//       }
//     })();

//     return () => {
//       cancelled = true;
//     };
//   }, []);



  return (
    <div>
      <AppBar theme="clear" height="none">
        <AppBarNav id="TagBack" aria-label="Back" onClick={props.history.goBack}>
          <ArrowBackSVGIcon />
        </AppBarNav>
        <AppBarTitle
          style={{display: 'flex', alignItems: 'center',}}
        >
          Search
        </AppBarTitle>
      </AppBar>
      <div>
        
        <div style={{width: '100%',display: 'flex', flexDirection: 'row',padding: 5,}}>
          <input
            type="text"
            value={textData}
            onChange={e => setTextdata(e.target.value)}
            style={{borderRadius: 10,width: '80%',marginRight: '2%',border: '1px solid #9c27b0',padding: 5,fontSize: 16,}}
          />
          <Button
              id="icon-button-7"
              buttonType="icon"
              theme="primary"
              type="submit"
              themeType="outline"
              aria-label="Favorite"
              onClick={() => handleSubmit()}
            >
            <FontIcon>search</FontIcon>
          </Button>
        </div>
        <span style={{textAlign: 'left', fontSize: 16,paddingLeft: 10,color: 'red',width: '100%',}}>{errorText}</span>
        <div style={{width: '100%',}}>
          {loading && <CircularProgress id="loading-tab-2" />}
          <List style={{display: 'flex', flexDirection: 'column', flex: 1,}}>
              
              {searching ? data.map(({ profile_pic, titbit_id, created_by, title, numbers, date, admin_edit, tags_list, tibits_img, input, source_url, src, color, source_name, graphTitle, years }) => (
                <ListItem key={titbit_id} id={`simple-item-${titbit_id}`} style={{padding: 1,}}>
                  <CustomRow
                      title={created_by}
                      description={title}
                      image_url={profile_pic}
                      // favorite={favourite}
                      numbersData={numbers}
                      tbDate={date}
                      editedBy={admin_edit}
                      tagData={tags_list}
                      // navigate={this.props.navigation.navigate}
                      tbImage={tibits_img}
                      searchData={false}
                      tbID={titbit_id}
                      // tbIndex={index}
                      // userID={this.props.loginDetails.id}
                      orgData={input}
                      sourceUrl={source_url}
                      srcVal={src}
                      bcgColor={color}
                      sourceName={source_name}
                      graphTitle={graphTitle}
                      yearsData={years}
                  />
                </ListItem>
              ))
              : null}
              {/* {loadmore ? <Button onClick={loadMoreSearch} theme={loadingmore ? 'disabled' : 'primary'} themeType="outline"><TextIconSpacing icon={ loadingmore ? (<CircularProgress id={'loadmore-id'} centered={false} />) : (<CachedSVGIcon />)}>Load More</TextIconSpacing></Button> : null} */}

              {loadmore ? <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', margin: 'auto', paddingRight: 5,}}><Button id="icon-button-9" buttonType="icon" theme={loadingmore ? 'disabled' : 'primary'} themeType="contained" aria-label="Load More" onClick={loadMoreSearch} > {loadingmore ? <CircularProgress id={'loadmore-Trending'} centered={false} /> : <CachedSVGIcon />}  </Button></div> : null}

              { visible ? <Button floating="bottom-left" theme="clear" onClick={scrollToTop}><ArrowUpwardSVGIcon /></Button> : null }
          </List>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SearchTB);