import React, { useState, useCallback } from 'react';
// import { MediaContainer, MediaOverlay } from '@react-md/media';
// import { CircularProgress } from '@react-md/progress';
// import { TabPanel } from '@react-md/tabs';
// import { Text } from '@react-md/typography';
// import { GridList, GridListCell } from '@react-md/utils';
// import { List, ListItem } from '@react-md/list';
// import Container from './Container';
// import InfiniteScroll from 'react-infinite-scroller';
// import { useTimeout, useToggle } from '@react-md/utils';
// import { CachedSVGIcon, CallReceivedFontIcon, SearchSVGIcon } from '@react-md/material-icons';
// import { TextIconSpacing } from '@react-md/icon';
// import { Button } from '@react-md/button';
// import { Chip } from '@react-md/chip';
// import { useHistory } from "react-router-dom";
// import {
//   Form,
//   TextField,
// } from '@react-md/form';
// import { FontIcon } from '@react-md/icon';
import { AppBar, AppBarNav, AppBarTitle } from '@react-md/app-bar';
import {
  ArrowBackSVGIcon,
} from '@react-md/material-icons';

import { withRouter } from "react-router-dom";

import { useAppSize } from '@react-md/utils';
import { Text, TextContainer } from "@react-md/typography";
import logo from './logo.png';

const Team = (props) => {

  const context = useAppSize();

  const getLayoutWidth = (lytdata) => {
    if(lytdata.isPhone){
      return '100%';
    }
    if(lytdata.isTablet){
      return '50%';
    }
    if(lytdata.isDesktop){
      return '40%';
    }
    if(lytdata.isLargeDesktop){
      return '30%';
    }
  }

  return (
    <div>
      <AppBar theme="clear" height="none">
        <AppBarNav id="TagBack" aria-label="Back" onClick={props.history.goBack}>
          <ArrowBackSVGIcon />
        </AppBarNav>
        <AppBarTitle
          style={{display: 'flex', alignItems: 'center',}}
        >
          Contact Us
        </AppBarTitle>
      </AppBar>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        
        <p>Numero Kno</p>
        
      </div>
    </div>
  );
};

export default withRouter(Team);