import React, { useEffect, useState, useCallback } from 'react';
// import { MediaContainer, MediaOverlay } from '@react-md/media';
import { CircularProgress } from '@react-md/progress';
import { TabPanel } from '@react-md/tabs';
// import { Text } from '@react-md/typography';
// import { GridList, GridListCell } from '@react-md/utils';
import { List, ListItem } from '@react-md/list';
import CustomRow from './GraphDesign';
import CustomRowBlog from './TrendingDesign';
import CustomRowText from './TbDesign';
import Container from './Container';
import { CachedSVGIcon, SearchSVGIcon, CloseSVGIcon } from '@react-md/material-icons';
// import { TextIconSpacing } from '@react-md/icon';
import { Button } from '@react-md/button';
import { Chip } from '@react-md/chip';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addtbItem, addMoretbItem, setLoadmore, endLoadmore, setDataLoaded, setOverlay } from './reducer';
import { Overlay } from "@react-md/overlay";
import { AppSizeListener, useAppSize, useToggle } from '@react-md/utils';

// import { randomInt } from './random';

const GraphTB = (props) => {
  // const [{ loading, data }, setState] = useState({
  //   loading: false,
  //   data: [],
  // });
  const context = useAppSize();
  const dispatch = useDispatch();
  const graphLoading = useSelector(state => state.graphLoading);
  const graphData = useSelector(state => state.graphData);
  const graphLoadmore = useSelector(state => state.graphLoadmore);
  const graphLoadingmore = useSelector(state => state.graphLoadingmore);
  const graphStart = useSelector(state => state.graphStart);
  const graphEnd = useSelector(state => state.graphEnd);
  const isDataLoadedGB = useSelector(state => state.isDataLoadedGraph);
  const graphOffsetVal = useSelector(state => state.graphOffset);
  const blogOverlayDisp = useSelector(state => state.isBlogOverlay);
  const textOverlayDisp = useSelector(state => state.isTextOverlay);
  const blogOverlayData = useSelector(state => state.blogOverlay);
  const textOverlayData = useSelector(state => state.textOverlay);

  // const [loading, setLoading] = useState(false);
  // const [data, setData] = useState([]);
  // const [loadmore, setLoadmore] = useState(false);

  // const [loadingmore, setLoadingmore] = useState(false);

  // const [start_val, incStart] = useState(0);
  // const [end_val, incEnd] = useState(10);

  let history = useHistory();

  const loadFunc = useCallback(
    () => {
        // console.log("end reached");
        // setLoadingmore(true);
        dispatch(setLoadmore({
          loadingmore: true,
          loadingmoreName: 'graphLoadingmore',
        }));
        // startVal = endVal;
        // endVal = endVal + 10;
        // console.log(start_val);
        // console.log(end_val);
        (async function load() {
          const response = await fetch('https://api.numerokno.com/api/graphtitle/', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              "app-key": "E9FE46D9-FC53-480F-9DC6-D26A7DE233A0"
            },
            body: JSON.stringify({
              userId: 1,
              start_value: graphStart,
              end_value: graphEnd,
              offset: graphOffsetVal,
            })
          });
          const result = await response.json();
          
            // setData(prevState => ([...prevState, ...result]));
            // setLoadingmore(false);
            // incStart(end_val);
            // incEnd(end_val + 10);
            if(result.titbits.length === 0){
              // setLoadmore(false)
              // setLoadingMore(false);
              dispatch(endLoadmore({
                loadingmore: false,
                loadingmoreName: 'graphLoadingmore',
                loadmore: false,
                loadmoreName: 'graphLoadmore',
                loading: false,
                loadingName: 'graphLoading',
              }));
            }else{
              // setData(prevState => ([...prevState, ...result]));
              // setLoadingMore(false);
              // incStart(end_val);
              // incEnd(end_val + 10);
              dispatch(addMoretbItem({
                stateName: 'graphData',
                data: result.titbits,
                loadingmore: false,
                loadmore: true,
                startVal: graphEnd,
                endVal: (graphEnd + 10),
                loadingmoreName: 'graphLoadingmore',
                loadmoreName: 'graphLoadmore',
                startvalName: 'graphStart',
                endvalName: 'graphEnd',
                offsetName: 'graphOffset',
                offsetVal: result.offset,
              }));
            }

        })();
    },
    [dispatch, graphStart, graphEnd, graphOffsetVal],
  );

  useEffect(() => {
    let cancelled = false;
    // setLoading(true);
    // setState({ loading: true, data: [] });
    if(!isDataLoadedGB){
      (async function load() {
        const response = await fetch('https://api.numerokno.com/api/graphtitle/', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            "app-key": "E9FE46D9-FC53-480F-9DC6-D26A7DE233A0"
          },
          body: JSON.stringify({
            userId: 1,
            start_value: graphStart,
            end_value: graphEnd,
            offset: 0,
          })
        });
        const result = await response.json();
        const data = result;
      //   const start = randomInt({ min: 0, max: 20 });
      //   const total = randomInt({ min: 6, max: 14 });
      //   const data = list.slice(start, start + total).map(({ author, id }) => ({
      //     name: author,
      //     url: `https://picsum.photos/250?image=${id}`,
      //   }));
        // const reslen = result.length;
        if (!cancelled) {
          // setData(data);
          // setLoading(false);
          // setLoadmore(true);
          // incStart(10);
          // incEnd(20);
  
          if(result.titbits.length === 0){
            // setLoadmore(false)
            // setLoadingMore(false);
            dispatch(endLoadmore({
              loadingmore: false,
              loadingmoreName: 'graphLoadingmore',
              loadmore: false,
              loadmoreName: 'graphLoadmore',
              loading: false,
              loadingName: 'graphLoading',
            }));
          }else{
            // setData(prevState => ([...prevState, ...result]));
            // setLoadingMore(false);
            // incStart(end_val);
            // incEnd(end_val + 10);
            dispatch(addtbItem({
              stateName: 'graphData',
              data: result.titbits,
              loading: false,
              loadmore: true,
              startVal: graphEnd,
              endVal: (graphEnd + 10),
              loadingName: 'graphLoading',
              loadmoreName: 'graphLoadmore',
              startvalName: 'graphStart',
              endvalName: 'graphEnd',
              offsetName: 'graphOffset',
              offsetVal: result.offset,
            }));
          }
  
        }
      })();
    }
    

    return () => {
      cancelled = true;
      dispatch(setDataLoaded({
        stateName: 'isDataLoadedGraph',
        dataLoaded: true,
      }))
    };
  }, [dispatch]);

  const displayOverlay = useCallback(
    (actn, stateName) => {
      //   console.log(JSON.stringify(topicList))
      dispatch(setOverlay({
        stateName: stateName,
        action: actn,
      }));
    },[dispatch],
  )

  const getLayoutWidth = (lytdata) => {
    if(lytdata.isPhone){
      return '100%';
    }
    if(lytdata.isTablet){
      return '50%';
    }
    if(lytdata.isDesktop){
      return '40%';
    }
    if(lytdata.isLargeDesktop){
      return '30%';
    }
  }

  return (
    <TabPanel {...props}>
      <Container centered>
        <div style={{width: '100%', height: 50,}}>
          <List style={{display: 'flex', flexDirection: 'row', flex: 1,justifyContent: 'flex-start',overflowY: 'auto',}}>
            <Chip style={{marginLeft: 5,}} key={'searchbtn'} onClick={() => history.push({pathname: '/searchGraph',})} leftIcon={<SearchSVGIcon />}>Search</Chip>
            {/* {loadingtopic ? null : datatopic.map(({Topic_name}, index) => (
              <Chip style={{marginLeft: 5,}} onClick={() => history.push({pathname: '/topic', tagData: Topic_name,})} key={`${Topic_name}-${index}`}>{Topic_name}</Chip>))} */}
          </List>
        </div>
        <div style={{width: '100%',}}>
          <List style={{width: '100%'}}>
              {graphLoading && <CircularProgress id="loading-tab-graph" />}
              
              {graphData.map(({ graph_id, date, grph_t, graph_unit, y_axis, x_axis, x_datetype, graphType }, index) => (
                  <ListItem key={index} id={`graph-${index}-tb`} style={{padding: 1,}}>
                    <CustomRow
                        tbDate={date}
                        // searchData={"SearchTag"}
                        tbID={graph_id}
                        tbIndex={index}
                        graphTitle={grph_t}
                        graphUnit={graph_unit}
                        yAxis={y_axis}
                        xAxis={x_axis}
                        xDateTypes={x_datetype}
                        graphType={graphType}
                    />
                  </ListItem>
              ))}
              {/* {graphLoadmore ? <Button onClick={loadFunc} theme={graphLoadingmore ? 'disabled' : 'primary'} themeType="outline"><TextIconSpacing icon={ graphLoadingmore ? (<CircularProgress id={'loadmore-id'} centered={false} />) : (<CachedSVGIcon />)}>Load More</TextIconSpacing></Button> : null} */}
              {graphLoadmore ? <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', margin: 'auto', paddingRight: 5,}}><Button id="icon-button-9" buttonType="icon" theme={graphLoadingmore ? 'disabled' : 'primary'} themeType="contained" aria-label="Load More" onClick={loadFunc} > {graphLoadingmore ? <CircularProgress id={'Graph-loadmore'} centered={false} /> : <CachedSVGIcon />}  </Button></div> : null}
          </List>
        </div>
        <Overlay
          id="custom-theme-blog-overlay"
          // onRequestClose={() => displayOverlay(false, 'isBlogOverlay')}
          visible={blogOverlayDisp}
          style={{width: getLayoutWidth(context), overflowY: 'auto',}}
          portal={true}
        >
          <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', paddingTop: '5%', paddingBottom: '5%',overflowY: 'auto',}}>
            <div style={{display: 'flex', flexDirection: "column", width: '90%', margin: 'auto', backgroundColor: 'white', borderRadius: 10, paddingBottom: 5,}}>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: 5,}}>
                <span style={{textAlign: 'center', marginLeft: '5%'}}>Related Blog</span>
                <Button
                  id="Close-blog"
                  buttonType="icon"
                  theme="error"
                  aria-label="Close"
                  onClick={() => displayOverlay(false, 'isBlogOverlay')}
                >
                  <CloseSVGIcon />
                </Button>
              </div>
              
              <List style={{width: '100%', display: 'flex', flexDirection: "column", alignItems: 'flex-start', margin: 0,padding: 0,}}>
                {blogOverlayData.length === 0 ? <CircularProgress id="loading-tab-blog-overlay" /> : blogOverlayData.map((item, index) => (
                  <ListItem key={`'overlayBlog'-${item.titbit_id}-${index}`} style={{padding: 5,}}>
                    <CustomRowBlog
                        title={item.created_by}
                        // description={item.title}
                        // image_url={item.profile_pic}
                        tbDate={item.date}
                        // editedBy={item.admin_edit}
                        tagData={item.tags_list}
                        // tbImage={item.tibits_img}
                        searchData={true}
                        tbID={item.titbit_id}
                        // tbIndex={index}
                        orgData={item.input}
                        sourceUrl={item.source_url}
                        srcVal={item.src}
                        topicData={item.topic_data}
                        bcgColor={item.color}
                        sourceName={item.source_name}
                        site_disc={item.site_disc}
                      />
                </ListItem>
                ))}
              </List>
            </div>
          </div>
        </Overlay>
        <Overlay
          id="custom-theme-text-overlay"
          // onRequestClose={() => displayOverlay(false, 'isTextOverlay')}
          visible={textOverlayDisp}
          style={{width: getLayoutWidth(context), overflowY: 'auto'}}
          portal={true}
        >
          <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', paddingTop: '5%', paddingBottom: '5%',overflowY: 'auto',}}>
            <div style={{display: 'flex', flexDirection: "column", width: '90%', margin: 'auto', backgroundColor: 'white', borderRadius: 10, paddingBottom: 5,}}>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: 5,}}>
                <span style={{textAlign: 'center', marginLeft: '5%'}}>Related Titbits</span>
                <Button
                  id="Close-blog"
                  buttonType="icon"
                  theme="error"
                  aria-label="Close"
                  onClick={() => displayOverlay(false, 'isTextOverlay')}
                >
                  <CloseSVGIcon />
                </Button>
              </div>
              <List style={{display: 'flex', flex: 1, flexDirection: "column", margin: 0,padding: 0,}}>
                {textOverlayData.length === 0 ? <CircularProgress id="loading-tab-text-overlay" /> : textOverlayData.map((item, index) => (
                  <ListItem key={`'overlayText'-${item.titbit_id}-${index}`} style={{padding: 5,}}>
                    <CustomRowText
                        title={item.created_by}
                        description={item.title}
                        image_url={item.profile_pic}
                        // favorite={favourite}
                        numbersData={item.numbers}
                        tbDate={item.date}
                        editedBy={item.admin_edit}
                        tagData={item.tags_list}
                        // navigate={this.props.navigation.navigate}
                        tbImage={item.tibits_img}
                        searchData={true}
                        tbID={item.titbit_id}
                        // tbIndex={index}
                        // userID={this.props.loginDetails.id}
                        orgData={item.input}
                        sourceUrl={item.source_url}
                        srcVal={item.src}
                        bcgColor={item.color}
                        sourceName={item.source_name}
                        graphTitle={item.graphTitle}
                        yearsData={item.years}
                    />
                  </ListItem>
                ))}
              </List>
            </div>
          </div>
        </Overlay>
      </Container>
    </TabPanel>
  );
};

export default GraphTB;